import { Col, Row } from 'antd'
import styles from './cpu.module.scss'
import ServerInformation from 'components/Server/informations'

export default function CpuContentTabs({ setModal }: any) {
  return (
    <div className={styles.Container}>
      <ServerInformation setModal={setModal} />
      <div className={styles.Informations}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Server:</label>
              <h2>danilo-colo1</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Asset:</label>
              <h2>testing asset</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>CPU cores:</label>
              <h2>8</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>CPU logical processors:</label>
              <h2>16</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Family:</label>
              <h2>1</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Frequency:</label>
              <h2>3Ghz</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>CPU model name:</label>
              <h2>Intel Core i9-9900KS </h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Vendor:</label>
              <h2>alguma informação</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>CPU model:</label>
              <h2>23</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Stepping:</label>
              <h2>0</h2>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
