import { Typography } from 'antd'
const { Title } = Typography

import { Liquid } from '@ant-design/charts'
import styles from './index.module.css'

export default function LiquidCard({ title, subtitle, value, percent }: any) {
  var config = {
    percent,
    outline: {
      border: 0,
      distance: 0,
    },
    wave: { length: 228 },
    color: '#0A365C',
  }
  return (
    <div className={styles.Card}>
      <Title
        style={{ color: '#0A365C', lineHeight: 1.4, textAlign: 'center' }}
        level={2}
      >
        {title}
      </Title>
      <p style={{ marginTop: '-15px' }}>{subtitle}</p>
      <p style={{ marginTop: '-14px' }}>{value}</p>
      <Liquid
        width={300}
        style={{ position: 'relative', marginTop: '-130px' }}
        {...config}
      />
    </div>
  )
}
