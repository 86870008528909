import { Layout } from 'antd'
import SidebarMenu from '../SidebarMenu'

const { Content, Footer } = Layout

const DashboardContainer = ({ children }) => {

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SidebarMenu />
      <Layout className="site-layout">
        <Content style={{ margin: '0 16px' }}>
          <div className="site-layout-background" style={{ padding: 24 }}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default DashboardContainer
