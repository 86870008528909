import React, { useState } from 'react'
import { notification } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import Title from 'antd/lib/typography/Title'
import BreadcrumbComponent from 'components/Breadcrumb'
import SidebarMenu from 'components/SidebarMenu'
// @ts-ignore
import Graph from 'react-graph-vis'
import styles from './map.services.module.scss'
import leftClick from './leftClick.png'
import scroll from './scroll.png'
import Switch from './001-switch.png'
import Server from './003-server.png'
import PowerStrip from './004-power-strip.png'

const Context = React.createContext({ name: 'Default' })

const options = {
  layout: {
    hierarchical: false,
  },
  edges: {
    color: '#0a365c',
    size: 44,
  },
  nodes: {
    borderWidth: 0,
    borderWidthSelected: 12,
    size: 34,
    background: '#F3F7FA',
    color: {
      border: '#eee',
      background: '#333',
      size: 24,
      hover: {
        border: 'orange',
        background: 'black',
      },
    },
    font: { color: '#0a365c', weight: 800 },
    shapeProperties: {
      useBorderWithImage: true,
    },
  },
}

export default function MapService() {
  const [state, setState] = useState({
    counter: 5,
    graph: {
      nodes: [
        {
          id: 1,
          label: 'Switch 1',
          color: '#F4F7F9',
          shape: 'image',
          image: Switch,
        },
        {
          id: 2,
          label: 'Server 1',
          color: '#F4F7F9',
          shape: 'image',
          image: Server,
        },
        {
          id: 3,
          label: 'PowerStrip 1',
          color: '#F4F7F9',
          shape: 'image',
          image: PowerStrip,
        },
        {
          id: 4,
          label: 'Switch 2',
          color: '#F4F7F9',
          shape: 'image',
          image: Switch,
        },
        {
          id: 5,
          label: 'Server 2',
          color: '#F4F7F9',
          shape: 'image',
          image: Server,
        },

        // { id: 6, label: 'Service', type: 'service', color: '#e04141' }
      ],
      edges: [
        { from: 1, to: 2 },
        { from: 1, to: 3 },
        { from: 2, to: 4 },
        { from: 2, to: 5 },
      ],
    },
    events: {
      select: ({ nodes, edges }) => {
        openNotification('bottomRight')
      },
    },
  })
  const { graph, events } = state

  const [api, contextHolder] = notification.useNotification()

  const openNotification = (placement) => {
    api.info({
      message: `Notification ${placement}`,
      description: (
        <Context.Consumer>{({ name }) => `Hello, ${name}!`}</Context.Consumer>
      ),
      placement,
    })
  }

  return (
    <Context.Provider value={{ name: 'Ant Design' }}>
      {contextHolder}
      <div
        style={{ display: 'flex', minHeight: '100vh', flexDirection: 'row' }}
      >
        <SidebarMenu />
        <Content style={{ margin: '0 16px' }}>
          <BreadcrumbComponent breadcrumb={['Create new Service']} />
          <Title
            style={{ color: '#0A365C', lineHeight: 1.2, marginTop: 10 }}
            level={1}
          >
            Service Map
          </Title>
          <div className={styles.MapServiceCanvas}>
            <Graph
              graph={graph}
              options={options}
              events={events}
              style={{ height: '70%' }}
            />
            <div className={styles.MapServiceBottom}>
              <a href="#">
                <img src={leftClick} alt="Left click" />
                Left click and drag
              </a>
              <a href="#">
                <img src={scroll} alt="Scroll to zoom" />
                Scroll to zoom
              </a>
              <a href="#">Back</a>
            </div>
          </div>
        </Content>
      </div>
    </Context.Provider>
  )
}
