import { useState } from 'react'
import { Select, Typography } from 'antd'
import DashboardContainer from '../../components/DashboardContainer'
import ButtonOption from '../../components/ButtonOption'
const { Title } = Typography

import HomeGrid from '../../components/HomeGrid'

import { SiReadthedocs } from 'react-icons/si'
import { FaAlignJustify } from 'react-icons/fa'
import { AiOutlineCodeSandbox } from 'react-icons/ai'
import { FiServer } from 'react-icons/fi'
import { IoGitNetworkOutline } from 'react-icons/io5'
import RacksContent from './Contents/racks'
import AssetsContent from './Contents/assets'
import SoftwarerContent from './Contents/software'
import IpsContent from './Contents/ips'
import ServersContent from './Contents/servers'

function App() {
  const [selected, setSelected] = useState({ id: 0, name: 'Online assets' })
  const [selects, setSelects] = useState([
    { id: 0, name: 'Online assets' },
    { id: 1, name: 'Online racks' },
    { id: 2, name: 'Online softwares' },
    { id: 3, name: 'Online servers' },
    { id: 4, name: 'Online IPs' },
  ])

  return (
    <DashboardContainer>
      <Title style={{ color: '#0A365C', lineHeight: 1.2 }} level={1}>
        What would <br />
        you like to view today?
      </Title>
      <p style={{ fontSize: 20 }}>
        Select the options bellow to view your dashboard
      </p>
      <div className="d-sm-block d-lg-none">
        <Select
          defaultValue={JSON.stringify(selected)}
          style={{ width: 200, fontSize: 16 }}
          onChange={(e) => setSelected(JSON.parse(e))}
        >
          {selects.map((select: any) => (
            <Select.Option
              key={JSON.stringify(select)}
              value={JSON.stringify(select)}
            >
              {select.name}
            </Select.Option>
          ))}
        </Select>
      </div>

      {/* <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={8} lg={4} xl={4}>
          <ButtonOption
            icon={<SiReadthedocs />}
            active={selected.id === 0 ? 'ok' : ''}
            title="Online assets"
            value={152}
            onClick={() => setSelected({ id: 0, name: 'Online assets' })}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={4} xl={8}>
          <ButtonOption
            icon={<FaAlignJustify />}
            active={selected.id === 1 ? 'ok' : ''}
            title="Online Racks"
            value={1125}
            onClick={() => setSelected({ id: 1, name: 'Online racks' })}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={4} xl={4}>
          <ButtonOption
            icon={<AiOutlineCodeSandbox />}
            active={selected.id === 2 ? 'ok' : ''}
            title="Online softwares"
            value={325}
            onClick={() => setSelected({ id: 2, name: 'Online softwares' })}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={4} xl={4}>
          <ButtonOption
            icon={<FiServer />}
            active={selected.id === 3 ? 'ok' : ''}
            title="Online servers"
            value={2588}
            onClick={() => setSelected({ id: 3, name: 'Online servers' })}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={4} xl={4}>
          <ButtonOption
            icon={<IoGitNetworkOutline />}
            active={selected.id === 4 ? 'ok' : ''}
            title="Online IPs"
            value={1477}
            onClick={() => setSelected({ id: 4, name: 'Online IPs' })}
          />
        </Col>
      </Row> */}

      <div className="d-none d-lg-block">
        <HomeGrid style={{ marginTop: '2rem' }}>
          <div className="grid-1">
            <ButtonOption
              icon={<SiReadthedocs />}
              active={selected.id === 0 ? 'ok' : ''}
              title="Online assets"
              value={152}
              onClick={() => setSelected({ id: 0, name: 'Online assets' })}
            />
          </div>
          <div className="grid-1">
            <ButtonOption
              icon={<FaAlignJustify />}
              active={selected.id === 1 ? 'ok' : ''}
              title="Online Racks"
              value={1125}
              onClick={() => setSelected({ id: 1, name: 'Online racks' })}
            />
          </div>
          <div className="grid-1">
            <ButtonOption
              icon={<AiOutlineCodeSandbox />}
              active={selected.id === 2 ? 'ok' : ''}
              title="Online software"
              value={325}
              onClick={() => setSelected({ id: 2, name: 'Online softwares' })}
            />
          </div>
          <div className="grid-1">
            <ButtonOption
              icon={<FiServer />}
              active={selected.id === 3 ? 'ok' : ''}
              title="Online servers"
              value={2588}
              onClick={() => setSelected({ id: 3, name: 'Online servers' })}
            />
          </div>
          <div className="grid-1">
            <ButtonOption
              icon={<IoGitNetworkOutline />}
              active={selected.id === 4 ? 'ok' : ''}
              title="Online IPs"
              value={1477}
              onClick={() => setSelected({ id: 4, name: 'Online IPs' })}
            />
          </div>
        </HomeGrid>
      </div>
      {selected.id === 1 ? (
        <RacksContent />
      ) : selected.id === 0 ? (
        <AssetsContent />
      ) : selected.id === 2 ? (
        <SoftwarerContent />
      ) : selected.id === 4 ? (
        <IpsContent />
      ) : (
        <ServersContent />
      )}
    </DashboardContainer>
  )
}

export default App
