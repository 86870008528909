import { useState } from 'react'
import modalStyles from '../../../css/modal.module.scss'
import styles from '../../Auth/auth.module.scss'
import BreadcrumbComponent from '../../../components/Breadcrumb'
import { Col, Row, Typography, Form } from 'antd'
import Icon, { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Layout } from 'antd'
const { Content } = Layout
import SidebarMenu from 'components/SidebarMenu'
import Modal from 'antd/lib/modal/Modal'
import FormInput from 'components/Form/input'
import '../../../css/modal.css'
import ButtonsDoubleAction from 'components/Buttons/buttonsDoubleAction'
import UploadFile from 'components/UploadFile'
import FormSelect from 'components/Form/select'
import FormDate from 'components/Form/date'

const { Title } = Typography

export default function SoftwareInstallation({ history }: any) {
  const [servicesModal, setServicesModal] = useState(false)

  return (
    <>
      <Modal
        style={{ zIndex: 99999999 }}
        footer={[]}
        className={modalStyles.Modal}
        width={900}
        visible={servicesModal}
        onCancel={() => setServicesModal(false)}
      >
        <h4>Create new ..</h4>

        <Row className={modalStyles.Row}>
          <Col className={modalStyles.Input} xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form layout="vertical">
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormInput
                    type="text"
                    required
                    options={{ key: 'new_vendor_name', label: 'Name' }}
                  />
                </Col>
              </Row>
              <div style={{ marginTop: 20 }}>
                <ButtonsDoubleAction
                  titlePrimary="Create"
                  otherTitle="Cancel"
                  onClickOther={() => setServicesModal(false)}
                  onClickPrimary={() => setServicesModal(false)}
                />
              </div>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Layout style={{ minHeight: '100vh' }}>
        <SidebarMenu />
        <Layout className="site-layout">
          <Content style={{ margin: '0 16px' }}>
            <div className="site-layout-background" style={{ padding: 24 }}>
              <BreadcrumbComponent breadcrumb={['Software', 'Add Software Installation']} />
              <Title style={{ color: '#0A365C', lineHeight: 1.2, marginTop: 10 }} level={1}>
                Add Software Installation
              </Title>
              <form className={styles.AuthForm}>
                <Row gutter={[30, 20]}>

                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Row gutter={[40, 20]}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                        <FormSelect
                          hasButton={true}
                          buttonAction={() => setServicesModal(true)}
                          options={{ key: 'service', label: 'Software version' }}
                          optionsSelect={['v1.2', 'v1.3']}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                        <FormSelect
                          hasButton={true}
                          buttonAction={() => setServicesModal(true)}
                          options={{ key: 'service', label: 'Server' }}
                          optionsSelect={['SERVER01', 'SERVER02']}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                        <FormSelect
                          hasButton={true}
                          buttonAction={() => setServicesModal(true)}
                          options={{ key: 'service', label: 'Server' }}
                          optionsSelect={['SERVER01', 'SERVER02']}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <FormDate name="First detected at" />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <FormDate name="Installed at" />
                      </Col>

                    </Row>
                  </Col>
                </Row>

                <div className="mt-buttons">
                  <ButtonsDoubleAction titlePrimary="Add" otherTitle="Cancel" />
                </div>
              </form>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}
