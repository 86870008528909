import TextArea from 'antd/lib/input/TextArea'
import styles from './styles.module.scss'

export default function FormTextArea({ options, children, ...rest }: any) {
  return (
    <div className={styles.TextAreaGroup}>
      <label htmlFor={options.key}>{options.label}</label>
      <TextArea name={options.key} id={options.key} {...rest} />
    </div>
  )
}
