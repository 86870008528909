import { useState } from 'react'
import { Col, Row } from 'antd'
import FormInput from 'components/Form/input'
import FormSelectMultiples from 'components/Form/multiplesSelect'
import FormSelect from 'components/Form/select'
import FormTextArea from 'components/Form/textarea'
import styles from './tabs.module.scss'
import ButtonsDoubleAction from 'components/Buttons/buttonsDoubleAction'
import Checkbox from 'antd/lib/checkbox/Checkbox'

export default function TabDone() {

  const [tabActive, setTabActive] = useState('Tab viewer 2')
  const tabs = [
    'Tab viewer 1',
    'Tab viewer 2',
    'Tab viewer 3',
    'Tab viewer 4',
    'Tab viewer 5',
    'Tab viewer 6',
  ]
  return (
    <Row style={{ marginTop: 15 }} gutter={[20, 20]}>
      <Col xs={24} sm={24} md={24} lg={8} xl={6}>
        <ul className={styles.TabViewer}>
          {tabs.map((tab) => (
            <li
              key={tab}
              onClick={() => setTabActive(tab)}
              className={tabActive === tab ? styles.Active : ''}
            >
              {tab}
            </li>
          ))}
        </ul>
      </Col>
      <Col xs={24} sm={24} md={24} lg={16} xl={18}>
        <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormSelect
              hasButton={true}
              buttonAction={() => alert('modal')}
              options={{ key: 'manufacture', label: 'Manufacture' }}
              optionsSelect={['Select an manufacture', 'Rack 1']}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <FormInput
              type="text"
              required
              defaultValue="Lorem ipsum dolor"
              options={{ key: 'productName', label: 'Product Name' }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <FormInput
              type="text"
              required
              defaultValue="154KO78UKL9QT96"
              options={{ key: 'skuNumber', label: 'Sku number' }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <FormInput
              type="text"
              required
              defaultValue="154965"
              options={{ key: 'uuid', label: 'UUID' }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <FormInput
              type="text"
              required
              defaultValue="V1.5.2"
              options={{ key: 'version', label: 'Version' }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <FormInput
              type="text"
              required
              defaultValue="Lorem ipsum dolor"
              options={{ key: 'wokeUpType', label: 'Woke up type' }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <FormSelect
              type="text"
              required
              options={{ key: 'architecture', label: 'Architecture' }}
              optionsSelect={['amd64(x86)', 'amd64(x64)', 'other']}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <FormSelect
              type="text"
              required
              options={{ key: 'type', label: 'Type' }}
              optionsSelect={['Virtual', 'Physicist']}
            />
          </Col>
          <Col style={{ marginTop: 29 }} xs={24} sm={24} md={24} lg={4} xl={4}>
            <Checkbox onChange={() => {}}>Full depth</Checkbox>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="mt-buttons">
              <ButtonsDoubleAction
                titlePrimary="Save informations"
                otherTitle="Cancel"
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
