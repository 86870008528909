import { useState } from 'react'
import BreadcrumbComponent from '../../../components/Breadcrumb'
import { Layout, List } from 'antd'
const { Content } = Layout
import SidebarMenu from 'components/SidebarMenu'
import { Col, Row, Typography } from 'antd'
import Filter from '../../../components/Table/filter'
import TableGrid from '../../../components/TableGrid'
import { HiPencil } from 'react-icons/hi'
import { FaBrain, FaTrashAlt } from 'react-icons/fa'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import styles from './styles.module.scss'

const { Title } = Typography

export default function DevicesServers({ history }: any) {
  const [filterModal, setFilterModal] = useState(false)
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows
      )
    },
    getCheckboxProps: (record: any) => {
      console.log(record)
      return {
        disabled: record.title === 'Disabled User',
        title: record.title,
      }
    },
  }

  const data = [
    {
      name: {
        name: 'Name',
        value: 'Test',
      },
      displayName: {
        name: 'Display name',
        value: 'display name of test',
      },
      description: {
        name: 'Description',
        value: 'description test',
      },
      category: {
        name: 'Category',
        value: 'category test',
      },
    },
    {
      name: {
        name: 'Name',
        value: 'Test',
      },
      displayName: {
        name: 'Display name',
        value: 'display name of test',
      },
      description: {
        name: 'Description',
        value: 'description test',
      },
      category: {
        name: 'Category',
        value: 'category test',
      },
    },
    {
      name: {
        name: 'Name',
        value: 'Test',
      },
      displayName: {
        name: 'Display name',
        value: 'display name of test',
      },
      description: {
        name: 'Description',
        value: 'description test',
      },
      category: {
        name: 'Category',
        value: 'category test',
      },
    },
  ]

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <SidebarMenu />
        <Layout className="site-layout">
          <Content style={{ margin: '0 16px' }}>
            <div className="site-layout-background" style={{ padding: 24 }}>
              <BreadcrumbComponent breadcrumb={['Devices', 'Servers']} />
              <Title
                style={{ color: '#0A365C', lineHeight: 1.2, marginTop: 10 }}
                level={1}
              >
                Servers
              </Title>

              <Filter
                textButton2="Create new server"
                onClickBuutton2={() => history.push('/devices/server/create')}
                filterModal={setFilterModal}
                textCount="100 servers"
                inputFilterData={[
                  'User name',
                  'Email address',
                  'First name',
                  'Last name',
                  'Groups',
                  'Actions',
                ]}
              />

              <div className={styles.TableDesktop}>
                <TableGrid
                  rowSelection={{
                    type: '',
                    ...rowSelection,
                  }}
                  columns={[
                    {
                      title: 'Hostname',
                      dataIndex: 'name',
                      key: 'name',
                    },
                    {
                      title: 'Type',
                      dataIndex: 'display_name',
                      key: 'display_name',
                    },
                    {
                      title: 'Environment',
                      dataIndex: 'type',
                      key: 'type',
                    },
                    {
                      title: 'is active?',
                      dataIndex: 'category',
                      key: 'category',
                    },
                    {
                      title: 'is full depth?',
                      dataIndex: 'vendor',
                      key: 'vendor',
                    },
                    {
                      title: 'Comments',
                      dataIndex: 'comments',
                      key: 'comments',
                    },
                    {
                      title: 'Serial number',
                      dataIndex: 'description',
                      key: 'description',
                    },
                    {
                      title: 'Services',
                      dataIndex: 'services',
                      key: 'services',
                    },
                    {
                      title: 'Rack',
                      dataIndex: 'rack',
                      key: 'rack',
                    },
                    {
                      title: 'Asset',
                      dataIndex: 'asset',
                      key: 'asset',
                    },

                    {
                      title: 'Actions',
                      key: 'actions',
                      render: () => (
                        <div className={styles.ActionButtons}>
                          <button className={styles.ActionButtonUpdate}>
                            <HiPencil />
                          </button>

                          <button className={styles.ActionButtonRemove}>
                            <FaTrashAlt />
                          </button>
                        </div>
                      ),
                    },
                  ]}
                  data={[
                    {
                      key: '1',
                      name: 'Teste',
                      display_name: 'teste type',
                      type: 'Encironment',
                      category: 'Teste',
                      vendor: 'Test',
                      comments: 'lorem impsum',
                      description: 'Test',
                      asset: 'Test',
                      services: 'Service test',
                      rack: 'Test rack',
                    },
                    {
                      name: 'Teste',
                      display_name: 'teste type',
                      type: 'Encironment',
                      category: 'Teste',
                      vendor: 'Test',
                      comments: 'lorem impsum',
                      description: 'Test',
                      asset: 'Test',
                      services: 'Service test',
                      rack: 'Test rack2s',
                    },
                  ]}
                />
              </div>

              <div className={styles.TableResponsive}>
                <List
                  itemLayout="horizontal"
                  pagination={{
                    onChange: (page) => {
                      console.log(page)
                    },
                    pageSize: 3,
                  }}
                  size="large"
                  dataSource={data}
                  renderItem={(item: any) => (
                    <List.Item>
                      <Checkbox
                        style={{ margin: '5px 0 15px 0' }}
                        onChange={() => {}}
                      >
                        Select
                      </Checkbox>
                      <List.Item.Meta
                        title={item.name.name}
                        description={item.name.value}
                      />
                      <List.Item.Meta
                        title={item.displayName.name}
                        description={item.displayName.value}
                      />
                      <List.Item.Meta
                        title={item.description.name}
                        description={item.description.value}
                      />
                      <List.Item.Meta
                        title={item.category.name}
                        description={item.category.value}
                      />
                      <List.Item.Meta title={'Actions'} />
                      <div className={styles.ActionButtons}>
                        <button className={styles.ActionButtonUpdate}>
                          <HiPencil />
                        </button>

                        <button className={styles.ActionButtonRemove}>
                          <FaTrashAlt />
                        </button>
                      </div>
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}
