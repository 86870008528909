import { Col, Row } from 'antd'
import styles from './styles.module.scss'

export default function ServerInformation({ setModal }: any) {
  return (
    <div className={styles.ServerCard}>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={3}>
          <div className={styles.ServerIcon}>
            <img src="/icone-serve.png" alt="Icon Server" />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={18} xl={12} xxl={{ span: 12 }}>
          <Row>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <div className={styles.ServerInformations}>
                <div className={styles.ItemInformation}>
                  <h4>Hostname:</h4>
                </div>
                <div className={styles.ItemInformation}>
                  <h4>ICMDB ID:</h4>
                </div>
                <div className={styles.ItemInformation}>
                  <h4>Operating system:</h4>
                </div>
                <div className={styles.ItemInformation}>
                  <h4>Ram:</h4>
                </div>
                <div className={styles.ItemInformation}>
                  <h4>Disk space:</h4>
                </div>
                <div className={styles.ItemInformation}>
                  <h4>Processor:</h4>
                </div>
                <div className={styles.ItemInformation}>
                  <h4>Architecture:</h4>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={12} md={12} lg={16} xl={16} xxl={16}>
              <div className={styles.ServerInformations}>
                <div className={styles.ItemInformation}>
                  <h5>development.icmdb.io</h5>
                </div>
                <div className={styles.ItemInformation}>
                  <h5>3r454r</h5>
                </div>
                <div className={styles.ItemInformation}>
                  <h5>Ubuntu 20.04 LTS</h5>
                </div>
                <div className={styles.ItemInformation}>
                  <h5>2048 MB</h5>
                </div>
                <div className={styles.ItemInformation}>
                  <h5>120 GB</h5>
                </div>
                <div className={styles.ItemInformation}>
                  <h5>Core i9-9900KS 8n / 16th</h5>
                </div>
                <div className={styles.ItemInformation}>
                  <h5>amd64(x86_64)</h5>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={{ span: 6, offset: 3 }}>
          <div className={styles.Images}>
            <div onClick={() => setModal(true)} className={styles.Image}>
              <h3>Front image</h3>
              <img
                src="/data-center-multiple-rows-operational-server-racks-rwd.jpg.rendition.intel.web.576.324.png"
                alt=""
              />
            </div>
            <div onClick={() => setModal(true)} className={styles.Image}>
              <h3>Rear image</h3>
              <img
                src="/data-center-multiple-rows-operational-server-racks-rwd.jpg.rendition.intel.web.576.324.png"
                alt=""
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
