import { Col, Row, Tabs } from 'antd'
import styles from './memory.module.scss'
import ServerInformation from 'components/Server/informations'

export default function MemoryContentTabs({ setModal, setModal2 }: any) {
  return (
    <div className={styles.Container}>
      <Tabs type="card" defaultActiveKey="1" size="large">
        <Tabs.TabPane tab="Memory Arrays" key="1">
          <ServerInformation setModal={setModal} />
          <div className={styles.Informations2}>
            <div className={styles.CardTitle}>Informações da memória</div>
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <div className={styles.Infos}>
                  <h2>Size total (GB):</h2>
                  <h4>64GB</h4>
                </div>
                <div className={styles.Infos}>
                  <h2>Size used (GB):</h2>
                  <h4>16GB</h4>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <div className={styles.Infos}>
                  <h2>Slots total:</h2>
                  <h4>4</h4>
                </div>
                <div className={styles.Infos}>
                  <h2>Slots occuped:</h2>
                  <h4>2</h4>
                </div>
              </Col>
            </Row>
          </div>
          <div className={styles.Informations}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Server:</label>
                  <h2>danilo-colo1</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Use:</label>
                  <h2>System Memory</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Error connection:</label>
                  <h2>multi0-bit ECC</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Populated skits:</label>
                  <h2>8</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Total Slots:</label>
                  <h2>12</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Error information Hadle:</label>
                  <h2>65533</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Location:</label>
                  <h2>System board or montherboard</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Maximum Capaxity in byte:</label>
                  <h2>206158430208</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Numbers of Memory devices:</label>
                  <h2>12</h2>
                </div>
              </Col>
            </Row>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Memory Device" key="2">
          <ServerInformation setModal={setModal} />
          <div className={styles.CustomTable}>
            {[0, 1, 2].map((key) => (
              <div key={key} className={styles.Item}>
                <div>
                  <small>Memory Array</small>
                  <h4>danilo-colo1 - 12</h4>
                </div>
                <div>
                  <small>Attributes</small>
                  <h4>2</h4>
                </div>
                <div>
                  <small>Data width</small>
                  <h4>64</h4>
                </div>
                <div>
                  <small>Device set</small>
                  <h4>1</h4>
                </div>
                <div>
                  <small>Size in bytes</small>
                  <h4>2097152</h4>
                </div>
                <div>
                  <small>Details</small>
                  <h4 onClick={() => setModal2(true)} className={styles.ViewDetails}>
                    view details
                  </h4>
                </div>
              </div>
            ))}
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
