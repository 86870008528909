import { Col, Row, Tabs, Typography } from 'antd'
import LiquidCard from '../../../components/Liqid'
import CardRegister from '../../../components/CardRegister'

const { Title } = Typography

import CardMini from '../../../components/CardMini'

import Card from '../../../components/Card'
import ChartPie from '../../../components/ChartPie'
import ChartColumn from '../../../components/ChartColumn'

export default function ServersContent() {
  return (
    <>
      <Title
        style={{
          color: '#0A365C',
          lineHeight: 1.2,
          marginBottom: 35,
          paddingTop: 30,
        }}
        level={2}
      >
        You selected servers:
      </Title>

      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={5}>
          <Card>
            <ChartPie
              title="Graphic general of servers"
              color={['#0A365C', '#FFAE00', '#9EB6CB', '#FE854F']}
              data={[
                {
                  type: 'Servers in use',
                  value: 40,
                },
                {
                  type: 'Free servers',
                  value: 12,
                },
                {
                  type: 'Physical server',
                  value: 10,
                },
                {
                  type: 'Virtual server',
                  value: 18,
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={5}>
          <CardMini
            title="RAM sum of all servers"
            value="750GB"
            stage={2}
            color="#0A365C"
          />
          <CardMini
            title="CPU sum of all servers"
            value="858 IPs"
            stage={2}
            color="#FFAE00"
          />
          <CardMini
            title="Disk sum of all servers"
            value="889TB"
            stage={2}
            color="#9EB6CB"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={10}>
          <Tabs defaultActiveKey="1" onChange={() => {}}>
            <Tabs.TabPane tab="RAM" key="1">
              <ChartColumn
                title={true}
                color={['#FFAE00', '#0A365C']}
                data={[
                  {
                    country: 'Free RAM',
                    year: 'Jan',
                    value: 221,
                  },
                  {
                    country: 'Free RAM',
                    year: 'Feb',
                    value: 345,
                  },
                  {
                    country: 'Free RAM',
                    year: 'Mar',
                    value: 134,
                  },
                  {
                    country: 'Free RAM',
                    year: 'Apr',
                    value: 393,
                  },
                  {
                    country: 'Free RAM',
                    year: 'May',
                    value: 430,
                  },
                  {
                    country: 'Free RAM',
                    year: 'Jun',
                    value: 164,
                  },
                  {
                    country: 'Free RAM',
                    year: 'Jul',
                    value: 653,
                  },
                  {
                    country: 'Free RAM',
                    year: 'Aug',
                    value: 393,
                  },
                  {
                    country: 'Free RAM',
                    year: 'Sep',
                    value: 393,
                  },
                  {
                    country: 'Free RAM',
                    year: 'Oct',
                    value: 393,
                  },
                  {
                    country: 'Free RAM',
                    year: 'Nov',
                    value: 393,
                  },
                  {
                    country: 'Free RAM',
                    year: 'Dec',
                    value: 393,
                  },

                  {
                    country: 'Used RAM',
                    year: 'Jan',
                    value: 3634,
                  },
                  {
                    country: 'Used RAM',
                    year: 'Feb',
                    value: 2634,
                  },
                  {
                    country: 'Used RAM',
                    year: 'Mar',
                    value: 4634,
                  },
                  {
                    country: 'Used RAM',
                    year: 'Apr',
                    value: 5234,
                  },
                  {
                    country: 'Used RAM',
                    year: 'May',
                    value: 6634,
                  },
                  {
                    country: 'Used RAM',
                    year: 'Jun',
                    value: 1634,
                  },
                  {
                    country: 'Used RAM',
                    year: 'Jul',
                    value: 4834,
                  },
                ]}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="CPU" key="2">
              <ChartColumn
                title={true}
                color={['#FFAE00', '#0A365C']}
                data={[
                  {
                    country: 'Free CPU',
                    year: 'Jan',
                    value: 221,
                  },
                  {
                    country: 'Free CPU',
                    year: 'Feb',
                    value: 345,
                  },
                  {
                    country: 'Free CPU',
                    year: 'Mar',
                    value: 134,
                  },
                  {
                    country: 'Free CPU',
                    year: 'Apr',
                    value: 393,
                  },
                  {
                    country: 'Free CPU',
                    year: 'May',
                    value: 430,
                  },
                  {
                    country: 'Free CPU',
                    year: 'Jun',
                    value: 164,
                  },
                  {
                    country: 'Free CPU',
                    year: 'Jul',
                    value: 653,
                  },
                  {
                    country: 'Free CPU',
                    year: 'Aug',
                    value: 393,
                  },
                  {
                    country: 'Free CPU',
                    year: 'Sep',
                    value: 393,
                  },
                  {
                    country: 'Free CPU',
                    year: 'Oct',
                    value: 393,
                  },
                  {
                    country: 'Free CPU',
                    year: 'Nov',
                    value: 393,
                  },
                  {
                    country: 'Free CPU',
                    year: 'Dec',
                    value: 393,
                  },

                  {
                    country: 'Used CPU',
                    year: 'Jan',
                    value: 3634,
                  },
                  {
                    country: 'Used CPU',
                    year: 'Feb',
                    value: 2634,
                  },
                  {
                    country: 'Used CPU',
                    year: 'Mar',
                    value: 4634,
                  },
                  {
                    country: 'Used CPU',
                    year: 'Apr',
                    value: 5234,
                  },
                  {
                    country: 'Used CPU',
                    year: 'May',
                    value: 6634,
                  },
                  {
                    country: 'Used CPU',
                    year: 'Jun',
                    value: 1634,
                  },
                  {
                    country: 'Used CPU',
                    year: 'Jul',
                    value: 4834,
                  },
                ]}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Disk" key="3">
              <ChartColumn
                title={true}
                color={['#FFAE00', '#0A365C']}
                data={[
                  {
                    country: 'Free DISK',
                    year: 'Jan',
                    value: 221,
                  },
                  {
                    country: 'Free DISK',
                    year: 'Feb',
                    value: 345,
                  },
                  {
                    country: 'Free DISK',
                    year: 'Mar',
                    value: 134,
                  },
                  {
                    country: 'Free DISK',
                    year: 'Apr',
                    value: 393,
                  },
                  {
                    country: 'Free DISK',
                    year: 'May',
                    value: 430,
                  },
                  {
                    country: 'Free DISK',
                    year: 'Jun',
                    value: 164,
                  },
                  {
                    country: 'Free DISK',
                    year: 'Jul',
                    value: 653,
                  },
                  {
                    country: 'Free DISK',
                    year: 'Aug',
                    value: 393,
                  },
                  {
                    country: 'Free DISK',
                    year: 'Sep',
                    value: 393,
                  },
                  {
                    country: 'Free DISK',
                    year: 'Oct',
                    value: 393,
                  },
                  {
                    country: 'Free DISK',
                    year: 'Nov',
                    value: 393,
                  },
                  {
                    country: 'Free DISK',
                    year: 'Dec',
                    value: 393,
                  },

                  {
                    country: 'Used DISK',
                    year: 'Jan',
                    value: 3634,
                  },
                  {
                    country: 'Used DISK',
                    year: 'Feb',
                    value: 2634,
                  },
                  {
                    country: 'Used DISK',
                    year: 'Mar',
                    value: 4634,
                  },
                  {
                    country: 'Used DISK',
                    year: 'Apr',
                    value: 5234,
                  },
                  {
                    country: 'Used DISK',
                    year: 'May',
                    value: 6634,
                  },
                  {
                    country: 'Used DISK',
                    year: 'Jun',
                    value: 1634,
                  },
                  {
                    country: 'Used DISK',
                    year: 'Jul',
                    value: 4834,
                  },
                ]}
              />
            </Tabs.TabPane>
          </Tabs>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={4}>
          <CardRegister />
        </Col>
      </Row>
    </>
  )
}
