import { useState } from 'react'
import modalStyles from '../../../css/modal.module.scss'
import styles from '../../Auth/auth.module.scss'
import BreadcrumbComponent from '../../../components/Breadcrumb'
import { Col, Input, Row, Typography, Form, Button } from 'antd'
import { Layout } from 'antd'
const { Content } = Layout
import SidebarMenu from 'components/SidebarMenu'
import Modal from 'antd/lib/modal/Modal'
import FormInput from 'components/Form/input'
import FormSelect from 'components/Form/select'
import FormTextArea from 'components/Form/textarea'
import '../../../css/modal.css'
import ButtonsDoubleAction from 'components/Buttons/buttonsDoubleAction'

const { Title } = Typography

export default function CreateService({ history }: any) {
  const [servicesModal, setServicesModal] = useState(false)
  const [vendorModal, setVendorModal] = useState(false)

  return (
    <>
      <Modal
        style={{ zIndex: 99999999 }}
        footer={[]}
        className={modalStyles.Modal}
        width={900}
        visible={servicesModal}
        onCancel={() => setServicesModal(false)}
      >
        <h4>Create new Service</h4>

        <Row className={modalStyles.Row}>
          <Col
            className={modalStyles.Input}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
          >
            <Form layout="vertical">
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormInput
                    type="text"
                    required
                    options={{ key: 'new_service_name', label: 'Name' }}
                  />
                </Col>
              </Row>
              <div style={{ marginTop: 20 }}>
                <ButtonsDoubleAction
                  titlePrimary="Create"
                  otherTitle="Cancel"
                  onClickOther={() => setServicesModal(false)}
                  onClickPrimary={() => setServicesModal(false)}
                />
              </div>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        style={{ zIndex: 99999999 }}
        footer={[]}
        className={modalStyles.Modal}
        width={900}
        visible={vendorModal}
        onCancel={() => setVendorModal(false)}
      >
        <h4>Create new Vendor</h4>

        <Row className={modalStyles.Row}>
          <Col
            className={modalStyles.Input}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
          >
            <Form layout="vertical">
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormInput
                    type="text"
                    required
                    options={{ key: 'new_vendor_name', label: 'Name' }}
                  />
                </Col>
              </Row>
              <div style={{ marginTop: 20 }}>
                <ButtonsDoubleAction
                  titlePrimary="Create"
                  otherTitle="Cancel"
                  onClickOther={() => setVendorModal(false)}
                  onClickPrimary={() => setVendorModal(false)}
                />
              </div>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Layout style={{ minHeight: '100vh' }}>
        <SidebarMenu />
        <Layout className="site-layout">
          <Content style={{ margin: '0 16px' }}>
            <div className="site-layout-background" style={{ padding: 24 }}>
              <BreadcrumbComponent breadcrumb={['Create new Service']} />
              <Title
                style={{ color: '#0A365C', lineHeight: 1.2, marginTop: 10 }}
                level={1}
              >
                Create new Service
              </Title>
              <form className={styles.AuthForm}>
                <Row gutter={[40, 20]}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <FormInput
                      type="text"
                      required
                      options={{ key: 'name', label: 'Name' }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <FormInput
                      type="text"
                      required
                      options={{ key: 'display_name', label: 'Display name' }}
                    />
                  </Col>
                  <Col
                    className={styles.hasButton}
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={8}
                    xxl={8}
                  >
                    <FormSelect
                      hasButton={true}
                      buttonAction={() => setServicesModal(true)}
                      options={{ key: 'service', label: 'Service' }}
                      optionsSelect={['Teste1', 'tetse2']}
                    />
                  </Col>
                  <Col
                    className={styles.hasButton}
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={8}
                    xxl={8}
                  >
                    <FormSelect
                      hasButton={true}
                      buttonAction={() => setVendorModal(true)}
                      options={{ key: 'vendor', label: 'Vendor' }}
                      optionsSelect={['Teste1', 'tetse2']}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                    <FormInput
                      type="text"
                      required
                      options={{ key: 'category', label: 'Category' }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <FormTextArea
                      options={{ key: 'description', label: 'Description' }}
                    ></FormTextArea>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <FormTextArea options={{ key: 'notes', label: 'Notes' }} />
                  </Col>
                </Row>
                <div className="mt-buttons">
                  <ButtonsDoubleAction
                    titlePrimary="Create"
                    otherTitle="Cancel"
                  />
                </div>
              </form>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}
