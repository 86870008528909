import { useState } from 'react'
import { Col, Input, Row } from 'antd'
import styles from './register.module.scss'
import { FloatLabel } from '../../../components/Form/floatLable'
import { IoMdArrowForward, IoMdArrowBack } from 'react-icons/io'
import { Redirect } from 'react-router'

export default function RegisterStep({
  email,
  setEmail,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  step,
  setStep,
  history,
}: any) {
  return (
    <Row justify="space-between">
      <Col span={24}>
        <FloatLabel value={email} label="Type your email" name="email">
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.Input}
            autoComplete="off"
          />
        </FloatLabel>
      </Col>

      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <div style={{ paddingRight: 15 }} className={styles.Col}>
          <FloatLabel value={firstName} label="First Name" name="firstName">
            <Input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className={styles.Input}
              autoComplete="off"
            />
          </FloatLabel>
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <div style={{ paddingLeft: 15 }} className={styles.Col}>
          <FloatLabel value={lastName} label="Last Name" name="lastName">
            <Input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className={styles.Input}
              autoComplete="off"
            />
          </FloatLabel>
        </div>
      </Col>

      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <div style={{ paddingRight: 15 }} className={styles.Col}>
          <FloatLabel
            value={password}
            label="Type your password"
            name="password"
          >
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={styles.Input}
              autoComplete="off"
            />
          </FloatLabel>
        </div>
      </Col>

      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <div style={{ paddingLeft: 15 }} className={styles.Col}>
          <FloatLabel
            value={confirmPassword}
            label="Confirm password"
            name="confirmPassword"
          >
            <Input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className={styles.Input}
              autoComplete="off"
            />
          </FloatLabel>
        </div>
      </Col>

      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <div style={{ paddingRight: 15 }} className={styles.Col}>
          <button onClick={() => history.goBack()} className={styles.Previous}>
            {/* <IoMdArrowBack size={18} color="#555" /> */}
            Back to login
          </button>
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <div style={{ paddingLeft: 15 }} className={styles.Col}>
          <button onClick={() => setStep(1)} className={styles.Next}>
            Next step <IoMdArrowForward size={18} />
          </button>
        </div>
      </Col>
    </Row>
  )
}
