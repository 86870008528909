import { Layout, Typography, Tag } from 'antd'
import styles from './viewGroups.module.scss'
import SidebarMenu from 'components/SidebarMenu'
import BreadcrumbComponent from 'components/Breadcrumb'
import ButtonsDoubleAction from 'components/Buttons/buttonsDoubleAction'

export default function SettingsViewGroups() {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SidebarMenu />
      <Layout className="site-layout">
        <Layout.Content style={{ margin: '0 16px' }}>
          <div className="site-layout-background" style={{ padding: 24 }}>
            <BreadcrumbComponent breadcrumb={['Settings', 'Groups', 'View']} />
            <div className={styles.TitleButton}>
              <Typography.Title
                style={{ color: '#0A365C', lineHeight: 1.2, marginTop: 10, width: '100%' }}
                level={1}
              >
                View Group
              </Typography.Title>
              <ButtonsDoubleAction
                onClickPrimary={() => {}}
                onClickOther={() => {}}
                titlePrimary="Update"
                otherTitle="Remove"
              />
            </div>
            <div className={styles.CardGroups}>
              <div className={styles.Header}>
                <small>Groups name:</small>
                <h3>Admin</h3>
              </div>
              <div className={styles.Permissions}>
                <small>Permissions</small>
                <div className={styles.PermissionsRow}>
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                    <Tag key={i} style={{ marginBottom: 6 }} color={'geekblue'}>
                      PERMISSION MEMBER
                    </Tag>
                  ))}
                </div>
              </div>
              <div className={styles.Users}>
                <small>Users</small>
                <div className={styles.UsersRow}>
                  {[0, 1, 2].map((i) => (
                    <div className={styles.User}>felipecreativeddcombr</div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  )
}
