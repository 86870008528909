import styles from './index.module.css'

const HomeGrid = ({ children, style }) => {
  return (
    <div className={styles.grid} style={style}>
      {children}
    </div>
  )
}

export default HomeGrid
