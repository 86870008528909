import { useState } from 'react'
import BreadcrumbComponent from '../../../components/Breadcrumb'
import { Layout, Table, Tag, List, Button } from 'antd'
const { Content } = Layout
import SidebarMenu from 'components/SidebarMenu'
import { Col, Row, Typography } from 'antd'
import styles from './styles.module.scss'
import './table.css'
import { HiDotsHorizontal } from 'react-icons/hi'
import Modal from 'antd/lib/modal/Modal'

const { Title } = Typography

export default function Profile({ history }: any) {
  const [permissionsModal, setPermissionsModal] = useState(false)

  const dataSource = [
    {
      key: '1',
      groups: 'Admin',
      permissions: ['MEMBER', 'ADMIN', 'STAFF', 'DEV'],
    },
  ]

  const columns = [
    {
      title: 'Groups',
      dataIndex: 'groups',
      key: 'groups',
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
      render: (i: any) => (
        <div style={{ display: 'flex' }}>
          {i.map((pm: any) => (
            <Tag key={pm} style={{ display: 'block', marginBottom: 8 }} color={'geekblue'}>
              {pm}
            </Tag>
          ))}
          <Button
            style={{
              background: '#F4F7F9',
              padding: '2px',
              borderRadius: 5,
              lineHeight: 0,
              height: 24,
              borderColor: '#eee',
            }}
            className={styles.ButtonDropdown}
            onClick={() => {
              setPermissionsModal(true)
            }}
          >
            <HiDotsHorizontal color="#333" size={20} />
          </Button>
        </div>
      ),
    },
  ]

  const data = [
    {
      name: {
        name: 'Group',
        value: 'Admin',
      },
      permissions: {
        name: 'Permissions',
        value: 'Admin',
      },
    },
  ]

  return (
    <>
      <Modal
        title="Permissions"
        centered
        visible={permissionsModal}
        onOk={() => setPermissionsModal(false)}
        onCancel={() => setPermissionsModal(false)}
        width={800}
      >
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
      </Modal>
      <Layout style={{ minHeight: '100vh' }}>
        <SidebarMenu />
        <Layout className="site-layout">
          <Content style={{ margin: '0 16px' }}>
            <div className="site-layout-background" style={{ padding: 24 }}>
              <BreadcrumbComponent breadcrumb={['Profile']} />
              <Title style={{ color: '#0A365C', lineHeight: 1.2, marginTop: 10 }} level={1}>
                Profile
              </Title>
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                  <div className={styles.AgentInformations}>
                    <Row gutter={[30, 20]}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={10}>
                        <div className={styles.Profile}>
                          <div className={styles.ProfileAvatar}>
                            <img
                              src="http://afernandes.adv.br/wp-content/uploads/Team-Member-3.jpg"
                              alt=""
                            />
                          </div>
                          <h4>Felipe Henrique</h4>
                          <p>Administrador</p>
                          <button>Update profile</button>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={14}>
                        <div className={styles.Informations}>
                          <div>
                            <small>Name:</small>
                            <h3>Felipe Henrique Silva</h3>
                          </div>
                          <div>
                            <small>Username:</small>
                            <h3>felipecreativeddcombr</h3>
                          </div>
                          <div>
                            <small>Email Address:</small>
                            <h3>felipe@creativedd.com.br</h3>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                  <div style={{ padding: '0 15px' }} className={styles.AgentInformations}>
                    <div className={styles.TableDesktop}>
                      <Table pagination={false} dataSource={dataSource} columns={columns} />
                    </div>
                  </div>
                  <div className={styles.TableResponsive}>
                    <List
                      itemLayout="horizontal"
                      pagination={false}
                      size="large"
                      dataSource={data}
                      renderItem={(item: any) => (
                        <List.Item>
                          <List.Item.Meta title={item.name.name} description={item.name.value} />

                          <List.Item.Meta
                            title={item.permissions.name}
                            description={item.permissions.value}
                          />
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Tag style={{ display: 'block', marginBottom: 8 }} color={'geekblue'}>
                              ADMIN
                            </Tag>
                            <Tag style={{ display: 'block', marginBottom: 8 }} color={'geekblue'}>
                              MEMBER
                            </Tag>
                            <Tag style={{ display: 'block', marginBottom: 8 }} color={'geekblue'}>
                              STAFF
                            </Tag>

                            <Button
                              style={{
                                background: '#F4F7F9',
                                padding: '2px',
                                borderRadius: 5,
                                lineHeight: 0,
                                height: 24,
                                borderColor: '#eee',
                              }}
                              className={styles.ButtonDropdown}
                              onClick={() => {
                                setPermissionsModal(true)
                              }}
                            >
                              <HiDotsHorizontal color="#333" size={20} />
                            </Button>
                          </div>
                        </List.Item>
                      )}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}
