import { Col, Row } from 'antd'
import styles from './network.module.scss'
import ServerInformation from 'components/Server/informations'

export default function NetworkContentTabs({ setModal }: any) {
  return (
    <div className={styles.Container}>
      <ServerInformation setModal={setModal} />
      <div className={styles.Informations}>
        <Row gutter={[30, 30]}>
          <div className={styles.Row}>
            <div className={styles.Item}>
              <label>Name:</label>
              <h2>docker</h2>
            </div>
            <div className={styles.Item}>
              <label>Parent LAG:</label>
              <h2>lag-02</h2>
            </div>
            <div className={styles.Item}>
              <label>Type:</label>
              <h2>QSFP+</h2>
            </div>
            <div className={styles.Item}>
              <label>Management only:</label>
              <div className={styles.Active}>Yes</div>
            </div>
            <div className={styles.Item}>
              <label>Untagged VLAN:</label>
              <h2>Bi-VLAN</h2>
            </div>
            <div className={styles.Item}>
              <label>TTagged VLANs:</label>
              <h2>vlan01, vlan02, vlan03</h2>
            </div>
            <div className={styles.Item}>
              <label>Assigned object type:</label>
              <h2>switch</h2>
            </div>
            <div className={styles.Item}>
              <label>Status:</label>
              <div className={styles.Active}>active</div>
            </div>
            <div className={styles.Item}>
              <label>Speed:</label>
              <h2>40G</h2>
            </div>
            <div className={styles.Item}>
              <label>Poe status:</label>
              <h2>connected</h2>
            </div>
          </div>
        </Row>
      </div>
    </div>
  )
}
