import { Button } from 'antd'
import styles from './styles.module.scss'

export default function ButtonsDoubleAction({
  titlePrimary,
  otherTitle,
  onClickPrimary,
  onClickOther,
  typeButtons,
}: any) {
  return (
    <div
      className={
        typeButtons === 'ok-and-remove'
          ? styles.ButtonAddRemove
          : styles.Buttons
      }
    >
      <Button onClick={onClickOther} size="large">
        {otherTitle}
      </Button>
      <Button onClick={onClickPrimary} type="primary" size="large">
        {titlePrimary}
      </Button>
    </div>
  )
}
