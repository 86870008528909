import { Select } from 'antd'
import styles from './styles.module.scss'

export default function FormSelectMultiples({
  options,
  children,
  hasButton,
  buttonAction,
  optionsSelect,
  ...rest
}: any) {
  return (
    <div className={styles.SelectGroup}>
      <label htmlFor={options.key}>{options.label}</label>
      <div>
        <Select
          mode="multiple"
          placeholder="Column visibility and order"
          onChange={() => {}}
          optionLabelProp="label"
        >
          {optionsSelect.map((filterData: any) => (
            <Select.Option
              key={filterData}
              value={filterData}
              label={filterData}
            >
              <div className="demo-option-label-item">{filterData}</div>
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  )
}
