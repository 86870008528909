import { Col, Row, Tabs } from 'antd'
import styles from './memory.module.scss'
import ServerInformation from 'components/Server/informations'

export default function StorageContentTabs({ setModal }: any) {
  return (
    <div className={styles.Container}>
      <ServerInformation setModal={setModal} />

      <div className={styles.Informations}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Server:</label>
              <h2>danilo-colo1</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Bus info:</label>
              <h2>bus informations</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Capabilities:</label>
              <h2>null</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Claimed:</label>
              <div className={styles.Active}>Yes</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Class:</label>
              <h2>name of class</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Clock:</label>
              <h2>12</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Driver:</label>
              <h2>system drive</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Latency:</label>
              <h2>20ms</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Logical Name:</label>
              <h2>name</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Vendor:</label>
              <h2>vendor name</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Version:</label>
              <h2>V1.5.2</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Product:</label>
              <h2>product name</h2>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
