import { useState } from 'react'
import { Input } from 'antd'
import styles from './auth.module.scss'
import { FloatLabel } from '../../components/Form/floatLable'
import { Link } from 'react-router-dom'

export default function Auth() {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  return (
    <div className={styles.AuthContainer}>
      <div className={styles.AuthFormContent}>
        <div className={styles.Brand}>
          <img
            src="https://www.icmdb.io/wp-content/uploads/2021/06/5.png"
            alt=""
          />
        </div>
        <form className={styles.AuthForm}>
          <h2>System access</h2>
          <FloatLabel value={email} label="Type your email" name="email">
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={styles.InputLogin}
              autoComplete="off"
            />
          </FloatLabel>
          <div style={{ marginTop: 30 }}>
            <FloatLabel
              type="email"
              value={password}
              label="Type your password"
              name="password"
            >
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={styles.InputLogin}
                autoComplete="off"
              />
            </FloatLabel>
            <Link to="/auth/forgot-password">
              forgot my <b>password</b>
            </Link>
          </div>
          <div style={{ marginTop: 30 }}>
            <button type="submit">Login to continue</button>
            <h6>
              Don't have an account yet?
              <Link to="/auth/register"> Create an account</Link>
            </h6>
          </div>
        </form>
      </div>
      <div className={styles.AuthBanner}></div>
    </div>
  )
}
