import { Col, Row, Typography } from 'antd'
import LiquidCard from '../../../components/Liqid'
import CardRegister from '../../../components/CardRegister'

import Card from '../../../components/Card'
import ChartPie from '../../../components/ChartPie'
const { Title } = Typography

import HomeGrid from '../../../components/HomeGrid'

export default function RacksContent() {
  return (
    <>
      <Title
        style={{
          color: '#0A365C',
          lineHeight: 1.2,
          marginBottom: 35,
          paddingTop: 30,
        }}
        level={2}
      >
        You selected racks:
      </Title>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={5}>
          <Card>
            <ChartPie
              title="Graphic general of softwares"
              color={['#0A365C', '#FFAE00', '#9EB6CB']}
              data={[
                {
                  type: 'Racks in use',
                  value: 60,
                },
                {
                  type: 'Free racks',
                  value: 30,
                },
                {
                  type: 'Busy racks',
                  value: 10,
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={5}>
          <LiquidCard
            percent={0.33}
            title="Racks in use"
            subtitle="Capacity"
            value="35/150"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={5}>
          <LiquidCard
            percent={0.28}
            title="Free racks"
            subtitle="Capacity"
            value="25/150"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={5}>
          <LiquidCard
            percent={0.28}
            title="Free racks"
            subtitle="Capacity"
            value="25/150"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={24} xxl={4}>
          <CardRegister />
        </Col>
      </Row>
    </>
  )
}
