import { useState, useEffect } from 'react'
import { Column } from '@ant-design/charts'
import styles from './styles.module.scss'

export default function ChartColumn({ title, data, color }: any) {
  var config = {
    data: data,
    xField: 'year',
    yField: 'value',
    seriesField: 'country',
    isGroupe: true,
    isPercent: false,
    isStack: true,
    intervalPadding: 6,
    color,
    tooltip: {
      formatter: function formatter(datum: any) {
        return {
          name: ''.concat(datum.value, ' '),
          value: datum.order_amt,
        }
      },
    },
    label: {
      style: { fill: '#fff', fontSize: 0 },
    },
    interactions: [
      { type: 'element-highlight-by-color' },
      { type: 'element-link' },
    ],
  }
  return (
    <div className={styles.Card}>
      {title ? (
        <h2>{title}</h2>
      ) : (
        <h2>
          Usage
          <br />
          history overt time
        </h2>
      )}

      <div className={styles.ColumnScroll}>
        <div className={styles.Column}>
          {/* @ts-ignore */}
          <Column width={'200'} {...config} />
        </div>
      </div>
    </div>
  )
}
