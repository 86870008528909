import { Col, Row, Typography } from 'antd'
import LiquidCard from '../../../components/Liqid'
import CardRegister from '../../../components/CardRegister'
import ChartColumn from '../../../components/ChartColumn'

const { Title } = Typography

export default function AssetsContent() {
  return (
    <>
      <Title
        style={{
          color: '#0A365C',
          lineHeight: 1.2,
          marginBottom: 35,
          paddingTop: 30,
        }}
        level={2}
      >
        You selected assets:
      </Title>

      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={5}>
          <LiquidCard
            percent={0.33}
            title="Assets in use"
            subtitle="Capacity"
            value="35/150"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={5}>
          <LiquidCard
            percent={0.28}
            title="Free assets"
            subtitle="Capacity"
            value="25/150"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={24} xxl={9}>
          <ChartColumn
            color={['#FFAE00', '#0A365C']}
            data={[
              {
                country: 'Free assets',
                year: 'Jan',
                value: 221,
              },
              {
                country: 'Free assets',
                year: 'Feb',
                value: 345,
              },
              {
                country: 'Free assets',
                year: 'Mar',
                value: 134,
              },
              {
                country: 'Free assets',
                year: 'Apr',
                value: 393,
              },
              {
                country: 'Free assets',
                year: 'May',
                value: 430,
              },
              {
                country: 'Free assets',
                year: 'Jun',
                value: 164,
              },
              {
                country: 'Free assets',
                year: 'Jul',
                value: 653,
              },
              {
                country: 'Free assets',
                year: 'Aug',
                value: 393,
              },
              {
                country: 'Free assets',
                year: 'Sep',
                value: 393,
              },
              {
                country: 'Free assets',
                year: 'Oct',
                value: 393,
              },
              {
                country: 'Free assets',
                year: 'Nov',
                value: 393,
              },
              {
                country: 'Free assets',
                year: 'Dec',
                value: 393,
              },

              {
                country: 'Assets in use',
                year: 'Jan',
                value: 3634,
              },
              {
                country: 'Assets in use',
                year: 'Feb',
                value: 2634,
              },
              {
                country: 'Assets in use',
                year: 'Mar',
                value: 4634,
              },
              {
                country: 'Assets in use',
                year: 'Apr',
                value: 5234,
              },
              {
                country: 'Assets in use',
                year: 'May',
                value: 6634,
              },
              {
                country: 'Assets in use',
                year: 'Jun',
                value: 1634,
              },
              {
                country: 'Assets in use',
                year: 'Jul',
                value: 4834,
              },
            ]}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={5}>
          <CardRegister />
        </Col>
      </Row>
    </>
  )
}
