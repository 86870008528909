import { Layout, Typography, Row, Col, Modal } from 'antd'
import styles from './styles.module.scss'
import SidebarMenu from 'components/SidebarMenu'
import BreadcrumbComponent from 'components/Breadcrumb'
import { useState } from 'react'
import ServerContentTabs from './Contents/server'
import MemoryContentTabs from './Contents/memory'
import BiosContentTabs from './Contents/bios'
import BaseboardContentTabs from './Contents/baseboard'
import ChassisContentTabs from './Contents/chassis'
import CpuContentTabs from './Contents/cpu'
import DisksContentTabs from './Contents/disks'
import NetworkContentTabs from './Contents/network'
import ProcessesContentTabs from './Contents/processes'
import StorageContentTabs from './Contents/storage'
import SoftwareContentTabs from './Contents/software'
import RepositoryContentTabs from './Contents/repository'
import UserContentTabs from './Contents/user'
import memoryStyes from './Contents/memory.module.scss'

export default function ServersView() {
  const [active, setActive] = useState<string>('Server')
  const [modal, setModal] = useState<boolean>(false)
  const [modal2, setModal2] = useState<boolean>(false)

  const sideItems = [
    { name: 'Server' },
    { name: 'BIOS' },
    { name: 'Baseboards' },
    { name: 'Chassis' },
    { name: 'CPU' },
    { name: 'Disks' },
    { name: 'Network Interfaces' },
    { name: 'Processes' },
    { name: 'RAM' },
    { name: 'Storage Controllers' },
    { name: 'Software' },
    { name: 'Repositories' },
    { name: 'Users' },
  ]

  return (
    <>
      <Modal
        title="Image"
        centered
        visible={modal}
        onOk={() => setModal(false)}
        onCancel={() => setModal(false)}
        width={800}
      >
        <img
          style={{ width: '100%', borderRadius: 24 }}
          src="https://blog.avast.com/hubfs/avast-blog/Avast-Hygene---Windows-Server-vs-Windows-Header-Master-V1.jpg"
          alt=""
        />
      </Modal>
      <Modal
        title="Image"
        centered
        visible={modal2}
        onOk={() => setModal2(false)}
        onCancel={() => setModal2(false)}
        width={800}
      >
        <div className={memoryStyes.CustomTable}>
          <div style={{ flexWrap: 'wrap' }} className={memoryStyes.Item}>
            <div style={{ marginBottom: 20 }}>
              <small>Other field</small>
              <h4>Lorem Ipsum is simply dummy </h4>
            </div>
            <div style={{ marginBottom: 20 }}>
              <small>Other field</small>
              <h4>Lorem Ipsum is simply dummy </h4>
            </div>
            <div style={{ marginBottom: 20 }}>
              <small>Other field</small>
              <h4>Lorem Ipsum is simply dummy </h4>
            </div>
            <div style={{ marginBottom: 20 }}>
              <small>Other field</small>
              <h4>Lorem Ipsum is simply dummy </h4>
            </div>
            <div style={{ marginBottom: 20 }}>
              <small>Other field</small>
              <h4>Lorem Ipsum is simply dummy </h4>
            </div>
            <div style={{ marginBottom: 20 }}>
              <small>Other field</small>
              <h4>Lorem Ipsum is simply dummy </h4>
            </div>
            <div>
              <small>Other long field</small>
              <h4>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the.
              </h4>
            </div>
          </div>
        </div>
      </Modal>{' '}
      <Layout style={{ minHeight: '100vh' }}>
        <SidebarMenu />
        <Layout className="site-layout">
          <Layout.Content style={{ margin: '0 16px' }}>
            <div className="site-layout-background" style={{ padding: 24 }}>
              <BreadcrumbComponent breadcrumb={['Servers', 'View Server']} />
              <Typography.Title
                style={{ color: '#0A365C', lineHeight: 1.2, marginTop: 10, width: '100%' }}
                level={1}
              >
                View server
              </Typography.Title>

              <div className={styles.Container}>
                <Row gutter={[20, 20]}>
                  <Col xs={24} sm={24} md={24} lg={7} xl={4} xxl={3}>
                    <ul className={styles.Side}>
                      {sideItems.map((item) => (
                        <li
                          key={item.name}
                          onClick={() => setActive(item.name)}
                          className={item.name === active ? styles.Active : ''}
                        >
                          {item.name}
                        </li>
                      ))}
                    </ul>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={17} xl={20} xxl={21}>
                    {active === 'Server' ? (
                      <ServerContentTabs setModal={setModal} />
                    ) : active === 'BIOS' ? (
                      <BiosContentTabs setModal={setModal} />
                    ) : active === 'Baseboards' ? (
                      <BaseboardContentTabs setModal={setModal} />
                    ) : active === 'Chassis' ? (
                      <ChassisContentTabs setModal={setModal} />
                    ) : active === 'CPU' ? (
                      <CpuContentTabs setModal={setModal} />
                    ) : active === 'Disks' ? (
                      <DisksContentTabs setModal={setModal} />
                    ) : active === 'Network Interfaces' ? (
                      <NetworkContentTabs setModal={setModal} />
                    ) : active === 'Processes' ? (
                      <ProcessesContentTabs setModal={setModal} />
                    ) : active === 'Storage Controllers' ? (
                      <StorageContentTabs setModal={setModal} />
                    ) : active === 'Software' ? (
                      <SoftwareContentTabs setModal={setModal} setModal2={setModal2} />
                    ) : active === 'Repositories' ? (
                      <RepositoryContentTabs setModal={setModal} />
                    ) : active === 'Users' ? (
                      <UserContentTabs setModal={setModal} />
                    ) : (
                      <MemoryContentTabs setModal={setModal} setModal2={setModal2} />
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Layout.Content>
        </Layout>
      </Layout>
    </>
  )
}
