import styles from './styles.module.scss'
import { FaFilter, FaRegFileExcel } from 'react-icons/fa'
import { IoIosAdd } from 'react-icons/io'
import { HiDotsHorizontal } from 'react-icons/hi'
import { Button, Col, Dropdown, Menu, Row, Select } from 'antd'

const MenuDropdown = (
  <Menu onClick={() => console.log('click')}>
    <Menu.Item key="1">Duplicate</Menu.Item>
    <Menu.Item key="2">Remove</Menu.Item>
  </Menu>
)

export default function Filter({
  inputFilterData,
  filterModal,
  textButton2,
  onClickBuutton2,
  textCount,
}: any) {
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={5}>
          <h5 className={styles.CountServices}>
            {textCount ? textCount : '150 services'}
          </h5>
        </Col>
        <Col
          className={styles.ButtonsFilters}
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={18}
          xxl={19}
        >
          <div className={styles.Actions}>
            <div className={styles.Filter}>
              <div className="input-no-border">
                <Select
                  mode="multiple"
                  placeholder="Column visibility and order"
                  onChange={() => {}}
                  optionLabelProp="label"
                >
                  {inputFilterData.map((filterData: any) => (
                    <Select.Option
                      key={filterData}
                      value={filterData}
                      label={filterData}
                    >
                      <div className="demo-option-label-item">{filterData}</div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <button onClick={() => filterModal(true)}>
                <FaFilter size={16} />
              </button>
            </div>
            <button>
              <FaRegFileExcel color="#fff" />
              Download CSV
            </button>
            <button onClick={onClickBuutton2}>
              <IoIosAdd size={23} color="#fff" />
              {textButton2}
            </button>
            <Dropdown overlay={MenuDropdown}>
              <Button className={styles.ButtonDropdown}>
                <HiDotsHorizontal size={20} />
              </Button>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <div className={styles.FilterContainer}></div>
    </>
  )
}
