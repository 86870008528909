import { Row, Col, Typography, Tag } from 'antd'
import { Layout } from 'antd'
import SidebarMenu from 'components/SidebarMenu'
import BreadcrumbComponent from 'components/Breadcrumb'
import styles from './view.module.scss'
import ButtonsDoubleAction from 'components/Buttons/buttonsDoubleAction'

const { Content } = Layout
const { Title } = Typography

export default function SettingsUserView() {
  return (

    <Layout style={{ minHeight: '100vh' }}>
      <SidebarMenu />
      <Layout className="site-layout">
        <Content style={{ margin: '0 16px' }}>
          <div className="site-layout-background" style={{ padding: 24 }}>
            <div className={styles.TitleButton}>
              <Title style={{ color: '#0A365C', lineHeight: 1.2, marginTop: 10, width: '100%' }} level={1}>
                Create new User
              </Title>
              <ButtonsDoubleAction
                onClickPrimary={() => { }}
                onClickOther={() => { }}
                titlePrimary="Update"
                otherTitle="Remove"

              />
            </div>
            <BreadcrumbComponent breadcrumb={['Settings', 'User', `View Details`]} />

            <div className={styles.UserView}>
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={24} md={24} lg={12} xxl={6}>
                  <div className={styles.UserDetails}>
                    <div className={styles.UserImage}>
                      <img src="https://neo-labor.com/wp-content/uploads/2016/08/13.jpg" alt="" />
                    </div>
                    <div className={styles.UserDetailsTexts}>
                      <div>
                        <small>Name</small>
                        <h3>Felipe Henrique Silva</h3>
                      </div>
                      <div>
                        <small>Username</small>
                        <h3>felipecreativeddcombr</h3>
                      </div>
                      <div>
                        <small>Email Address</small>
                        <h3>felipe@creativedd.com.br</h3>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xxl={18}>
                  <div className={styles.UserGroups}>
                    <div className={styles.UserGroupsNav}>
                      <small>Group</small>
                      <h3>Admin</h3>
                    </div>
                    <div className={styles.UserGroupsPermissions}>
                      <small>Permissions</small>
                      <div className={styles.UserGroupPermissionsFlex}>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                          <Tag key={i} style={{ marginBottom: 6 }} color={'geekblue'}>
                            PERMISSION MEMBER
                          </Tag>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className={styles.UserGroups}>
                    <div className={styles.UserGroupsNav}>
                      <small>Group</small>
                      <h3>Admin</h3>
                    </div>
                    <div className={styles.UserGroupsPermissions}>
                      <small>Permissions</small>
                      <div className={styles.UserGroupPermissionsFlex}>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                          <Tag key={i} style={{ marginBottom: 6 }} color={'geekblue'}>
                            PERMISSION MEMBER
                          </Tag>
                        ))}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}