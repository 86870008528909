import { Col, Row, Typography } from 'antd'
import LiquidCard from '../../../components/Liqid'
import CardRegister from '../../../components/CardRegister'

const { Title } = Typography

import ChartColumn from '../../../components/ChartColumn'
import CardMini from '../../../components/CardMini'
import HomeGrid from '../../../components/HomeGrid'

export default function IpsContent() {
  return (
    <>
      <Title
        style={{
          color: '#0A365C',
          lineHeight: 1.2,
          marginBottom: 35,
          paddingTop: 30,
        }}
        level={2}
      >
        You selected IPs:
      </Title>

      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={5}>
          <CardMini title="Publics ip's" value="1.158 IPs" color="#0A365C" />
          <LiquidCard
            percent={0.33}
            title="Publics ip's"
            subtitle="Capacity"
            value="35/150"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={5}>
          <CardMini title="Free ip's" value="1.858 IPs" color="#FEC64F" />
          <LiquidCard
            percent={0.28}
            title="Free ip's"
            subtitle="Capacity"
            value="25/150"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={5}>
          <CardMini title="Busy ip's" value="858 IPs" color="#9EB6CB" />
          <LiquidCard
            percent={0.28}
            title="Busy ip's"
            subtitle="Capacity"
            value="25/150"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={5}>
          <CardMini title="Private ip's" value="179 IPs" color="#FE854F" />
          <LiquidCard
            percent={0.28}
            title="Private ip's"
            subtitle="Capacity"
            value="25/150"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={24} xxl={4}>
          <CardRegister />
        </Col>
      </Row>
    </>
  )
}
