import { Col, Row, Tabs } from 'antd'
import styles from './memory.module.scss'
import ServerInformation from 'components/Server/informations'

export default function DisksContentTabs({ setModal }: any) {
  return (
    <div className={styles.Container}>
      <Tabs type="card" defaultActiveKey="1" size="large">
        <Tabs.TabPane tab="Disks" key="1">
          <ServerInformation setModal={setModal} />
          <div className={styles.Informations}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Server:</label>
                  <h2>danilo-colo1</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Name:</label>
                  <h2>ubuntu2004</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Product:</label>
                  <h2>product name</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Serial:</label>
                  <h2>3434345134</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Permissions:</label>
                  <h2>Read Only, Removable, Rotational</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Asset:</label>
                  <h2>asset name</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Device slot:</label>
                  <h2>center</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Model:</label>
                  <h2>Kingstom</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Size in bytes:</label>
                  <h2>206158430208</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Disk type:</label>
                  <h2>SSD</h2>
                </div>
              </Col>
            </Row>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="File Systems" key="2">
          <ServerInformation setModal={setModal} />
          <div className={styles.Informations}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Server:</label>
                  <h2>danilo-colo1</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Disk:</label>
                  <h2>ubuntu2004</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Model:</label>
                  <h2>Kingstom</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Available in bytes:</label>
                  <h2>206158430208</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Free in bytes:</label>
                  <h2>206158430208</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Size in bytes:</label>
                  <h2>206158430208</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Total in bytes:</label>
                  <h2>206158430208</h2>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                <div className={styles.Item}>
                  <label>Type:</label>
                  <h2>Virtual</h2>
                </div>
              </Col>
            </Row>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
