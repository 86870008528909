import { FiArrowDownRight } from 'react-icons/fi'

import styles from './index.module.css'

const ButtonOption = ({ icon, title, value, onClick, active }: any) => {
  return (
    <button
      onClick={onClick}
      className={active ? styles.buttonOptionActive : styles.buttonOption}
    >
      <span className={styles.icon}>{icon}</span>
      <span className={styles.value}>{value}</span>
      <span className={styles.title}>{title}</span>
      <FiArrowDownRight className={styles.arrow} />
    </button>
  )
}

export default ButtonOption
