import { Col, Row, Typography } from 'antd'
import LiquidCard from '../../../components/Liqid'
import CardRegister from '../../../components/CardRegister'

import Card from '../../../components/Card'
import ChartPie from '../../../components/ChartPie'
const { Title } = Typography

import HomeGrid from '../../../components/HomeGrid'

export default function SoftwarerContent() {
  return (
    <>
      <Title
        style={{
          color: '#0A365C',
          lineHeight: 1.2,
          marginBottom: 35,
          paddingTop: 30,
        }}
        level={2}
      >
        You selected software:
      </Title>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={5}>
          <Card>
            <ChartPie
              title="Graphic general of software"
              color={['#0A365C', '#FFAE00', '#9EB6CB', '#434343', '#FE854F']}
              data={[
                {
                  type: 'Software types',
                  value: 40,
                },
                {
                  type: 'Software',
                  value: 12,
                },
                {
                  type: 'Software versions',
                  value: 10,
                },
                {
                  type: 'Software licenses',
                  value: 18,
                },
                {
                  type: 'Software installation',
                  value: 20,
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={5}>
          <LiquidCard percent={0.33} title="End of life" subtitle="" value="" />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={5}>
          <LiquidCard
            percent={0.28}
            title="End of support"
            subtitle=""
            value=""
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={5}>
          <LiquidCard
            percent={0.38}
            title="End of sec support"
            subtitle=""
            value=""
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={4}>
          <CardRegister />
        </Col>
      </Row>
    </>
  )
}
