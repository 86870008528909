import { Col, Row } from 'antd'
import styles from './processes.module.scss'
import ServerInformation from 'components/Server/informations'

export default function ProcessesContentTabs({ setModal }: any) {
  return (
    <div className={styles.Container}>
      <ServerInformation setModal={setModal} />
      <div className={styles.Informations}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Server:</label>
              <h2>danilo-colo1</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Switch:</label>
              <h2>switch-001</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Name:</label>
              <h2>web access</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Protocol:</label>
              <h2>tcp</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Port number:</label>
              <h2>80</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Up addresses:</label>
              <h2>127.0.0.1, 172.168.22.2</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Status:</label>
              <h2>running</h2>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
