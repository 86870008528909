import { useState } from 'react'
import { Input } from 'antd'
import styles from './auth.module.scss'
import { FloatLabel } from '../../components/Form/floatLable'
import { Link } from 'react-router-dom'

export default function Forgot() {
  const [email, setEmail] = useState<string>('')

  return (
    <div className={styles.AuthContainer}>
      <div className={styles.AuthFormContent}>
        <div className={styles.Brand}>
          <img
            src="https://www.icmdb.io/wp-content/uploads/2021/06/5.png"
            alt=""
          />
        </div>
        <form className={styles.AuthForm}>
          <h2>Forgot my password</h2>
          <FloatLabel value={email} label="Type your email" name="email">
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={styles.InputLogin}
              autoComplete="off"
            />
          </FloatLabel>

          <div style={{ marginTop: 30 }}>
            <button type="submit">Recover password</button>
            <h6>
              back to <Link to="/auth">Login</Link>
            </h6>
          </div>
        </form>
      </div>
      <div className={styles.AuthBanner}></div>
    </div>
  )
}
