import { Select } from 'antd'
import { Option } from 'antd/lib/mentions'
import { IoMdAdd } from 'react-icons/io'
import styles from './styles.module.scss'

export default function FormSelect({
  options,
  children,
  hasButton,
  buttonAction,
  optionsSelect,
  ...rest
}: any) {
  return (
    <div className={styles.SelectGroup}>
      <label htmlFor={options.key}>{options.label}</label>
      <div>
        <Select
          defaultValue="Select"
          style={{ width: '100%' }}
          onChange={() => {}}
        >
          {optionsSelect.map((opt: any) => (
            <Option value={opt}>{opt}</Option>
          ))}
        </Select>

        {hasButton ? (
          <button onClick={buttonAction} type="button">
            <IoMdAdd size={22} color="#FFF" />
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
