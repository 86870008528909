import styles from './styles.module.scss'
import { DatePicker } from 'antd'

export default function FormDate({ name }: any) {
  return (
    <div className={styles.FormDate}>
      <label htmlFor={name}>{name}</label>
      <DatePicker id={name} autoComplete={"off"} />
    </div>
  )
}