import { useRef } from 'react'
import styles from './styles.module.scss'
import { MdAdd } from 'react-icons/md'
import { useState } from 'react'

export default function UploadFile() {
  const [image, setImage] = useState<File | null>(null)
  const fileRef = useRef<HTMLInputElement>(null)

  function focusInputFile() {
    fileRef.current?.click()
  }

  function handleDelete() {
    setImage(null)
  }

  function handleUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) return
    if (!e.target.files[0]) return

    const file = e.target.files[0]
    setImage(file)
  }

  return (
    <>
      <div id="input-file33">
        <input
          onChange={(e) => handleUpload(e)}
          className={styles.Input}
          ref={fileRef}
          type="file"
        />
      </div>
      <div onClick={() => focusInputFile()} className={styles.UploadArea}>
        {image ? (
          <img className={styles.ImagePreviewer} src={URL.createObjectURL(image)} alt="" />
        ) : (
          <>
            <MdAdd size={26} />
            <p>Upload photo</p>
          </>
        )}
      </div>
      <div className={styles.UploadFooter}>
        <button type="button" onClick={() => handleDelete()}>
          Delete
        </button>
        <button type="button" onClick={() => focusInputFile()}>
          Change
        </button>
      </div>
    </>
  )
}
