import styles from './register.module.scss'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import { Link } from 'react-router-dom'

export default function DoneStep({ history }: any) {
  setTimeout(() => history.push('/auth'), 2500)

  return (
    <div className={styles.Done}>
      <IoMdCheckmarkCircle size={55} color="green" />
      <h3>
        Seu cadastro foi criado com <span>sucesso!</span> Você será
        redirecionado em breve para o login
      </h3>
      <p>
        Você não foi redirecionado? <Link to="/auth">Clique aqui</Link>
      </p>
    </div>
  )
}
