import { useState } from 'react'
import { Col, Row } from 'antd'
import FormInput from 'components/Form/input'

import FormSelectMultiples from 'components/Form/multiplesSelect'
import FormSelect from 'components/Form/select'
import FormTextArea from 'components/Form/textarea'

import styles from './tabs.module.scss'
import ButtonsDoubleAction from 'components/Buttons/buttonsDoubleAction'
import Checkbox from 'antd/lib/checkbox/Checkbox'

export default function TabManufacture() {
  const [tabActive, setTabActive] = useState('Tab viewer 2')
  const tabs = [
    'Tab viewer 1',
    'Tab viewer 2',
    'Tab viewer 3',
    'Tab viewer 4',
    'Tab viewer 5',
    'Tab viewer 6',
  ]
  return (
    <Row style={{ marginTop: 15 }} gutter={[20, 20]}>
      <Col xs={24} sm={24} md={24} lg={8} xl={6}>
        <ul className={styles.TabViewer}>
          {tabs.map((tab) => (
            <li
              key={tab}
              onClick={() => setTabActive(tab)}
              className={tabActive === tab ? styles.Active : ''}
            >
              {tab}
            </li>
          ))}
        </ul>
      </Col>
      <Col xs={24} sm={24} md={24} lg={16} xl={18}>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormInput
              type="text"
              required
              defaultValue="V1.5.2"
              options={{ key: 'version', label: 'Version' }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <FormInput
              type="text"
              required
              defaultValue="Lorem ipsum dolor"
              options={{ key: 'wakeUpType', label: 'Wake up type' }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <FormInput
              type="number"
              required
              defaultValue={1}
              options={{ key: 'height', label: 'Height' }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormSelect
              options={{ key: 'environment', label: 'Environment' }}
              optionsSelect={['Production', 'Development']}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormInput
              type="text"
              required
              defaultValue="Lorem ipsum dolor"
              options={{ key: 'family', label: 'Family' }}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Checkbox onChange={() => {}}>Full depth</Checkbox>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="mt-buttons">
              <ButtonsDoubleAction
                titlePrimary="Save informations"
                otherTitle="Cancel"
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
