import { Steps } from 'antd'
import { useState } from 'react'
import DoneStep from './done'
import OrganizationStep from './organization'
import RegisterStep from './register'
import styles from './register.module.scss'

export default function Register(props: any) {
  const [step, setStep] = useState<number>(0)

  const [organization, setOrganization] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  return (
    <div className={styles.RegisterContainer}>
      <div className={styles.Brand}>
        <img
          src="https://www.icmdb.io/wp-content/uploads/2021/06/5.png"
          alt=""
        />
      </div>
      <div className={styles.Steps}>
        <Steps type="navigation" size="default" current={step} percent={0}>
          <Steps.Step
            title="Register"
            description="Plase enter with your credentials"
          />
          <Steps.Step
            title="Create organization"
            description="Now, create your organization"
          />
          <Steps.Step
            title="Done"
            description="Finish your registration! To continue"
          />
        </Steps>
      </div>
      <div className={styles.StepsResponsive}>
        <Steps
          type="default"
          direction="vertical"
          size="default"
          current={step}
          percent={0}
        >
          <Steps.Step
            title="Register"
            description="Plase enter with your credentials"
          />
          <Steps.Step
            title="Create organization"
            description="Now, create your organization"
          />
          <Steps.Step
            title="Done"
            description="Finish your registration! To continue"
          />
        </Steps>
      </div>
      <div className={styles.Content}>
        {step === 0 ? (
          <RegisterStep
            email={email}
            setEmail={setEmail}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            step={step}
            setStep={setStep}
            history={props.history}
          />
        ) : step === 1 ? (
          <OrganizationStep
            organization={organization}
            setOrganization={setOrganization}
            step={step}
            setStep={setStep}
          />
        ) : (
          <DoneStep history={props.history} />
        )}
      </div>
    </div>
  )
}
