import { useState } from 'react'
import BreadcrumbComponent from '../../../components/Breadcrumb'
import modalStyles from '../../../css/modal.module.scss'
import '../../../css/modal.css'
import styles from './settings.module.scss'
import { Button, Dropdown, Layout, List, Tag } from 'antd'
const { Content } = Layout
import SidebarMenu from 'components/SidebarMenu'
import { Col, Input, Modal, Row, Select, Typography } from 'antd'
import Filter from '../../../components/Table/filter'
import TableGrid from '../../../components/TableGrid'
import { Form } from 'antd'
import { HiDotsHorizontal, HiPencil } from 'react-icons/hi'
import { FaBrain, FaTrashAlt } from 'react-icons/fa'
import FormInput from 'components/Form/input'
import ButtonsDoubleAction from 'components/Buttons/buttonsDoubleAction'
import Checkbox from 'antd/lib/checkbox/Checkbox'

const { Title } = Typography

export default function SettingsUser({ history }: any) {
  const [filterModal, setFilterModal] = useState(false)
  const [permissionsModal, setPermissionsModal] = useState(false)

  const MenuDropdown = (
    <div
      style={{
        background: '#FFF',
        padding: 10,
        paddingRight: 5,
        border: '1px solid #eee',
      }}
    >
      <Tag style={{ display: 'block', marginBottom: 8 }} color={'geekblue'}>
        PERMISSION MEMBER
      </Tag>
      <Tag style={{ display: 'block', marginBottom: 8 }} color={'geekblue'}>
        PERMISSION MEMBER
      </Tag>
      <Tag style={{ display: 'block', marginBottom: 8 }} color={'geekblue'}>
        PERMISSION MEMBER
      </Tag>
      <Tag style={{ display: 'block', marginBottom: 8 }} color={'geekblue'}>
        PERMISSION MEMBER
      </Tag>
      <Tag style={{ display: 'block', marginBottom: 8 }} color={'geekblue'}>
        PERMISSION MEMBER
      </Tag>
    </div>
  )

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows
      )
    },
    getCheckboxProps: (record: any) => {
      console.log(record)
      return {
        disabled: record.title === 'Disabled User',
        title: record.title,
      }
    },
  }

  const data = [
    {
      name: {
        name: 'Name',
        value: 'Teste',
      },
      displayName: {
        name: 'Email Address',
        value: 'email@email',
      },
      description: {
        name: 'First name',
        value: 'Jhon',
      },
      category: {
        name: 'Last name',
        value: 'Doe',
      },
      groups: {
        name: 'Permissions',
        value: ['Admin', 'Member', 'Developer', 'Member', 'Staff', 'Moderator'],
      },
    },
    {
      name: {
        name: 'Name',
        value: 'Teste2',
      },
      displayName: {
        name: 'Email Address',
        value: 'email@email',
      },
      description: {
        name: 'First name',
        value: 'Jhon1',
      },
      category: {
        name: 'Last name',
        value: 'Doe1',
      },
      groups: {
        name: 'Permissions',
        value: ['Admin', 'Member', 'Developer', 'Member', 'Staff', 'Moderator'],
      },
    },
  ]

  return (
    <>
      <Modal
        title="Permissions"
        centered
        visible={permissionsModal}
        onOk={() => setPermissionsModal(false)}
        onCancel={() => setPermissionsModal(false)}
        width={800}
      >
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
        <Tag style={{ marginBottom: 6 }} color={'geekblue'}>
          PERMISSION MEMBER
        </Tag>
      </Modal>
      <Modal
        footer={[]}
        className={modalStyles.Modal}
        width={900}
        visible={filterModal}
        onCancel={() => setFilterModal(false)}
      >
        <h4>Search filters</h4>
        <Row className={modalStyles.Row}>
          <Col
            className={modalStyles.Input}
            xs={24}
            sm={12}
            md={15}
            lg={16}
            xl={17}
          >
            <Select
              mode="multiple"
              placeholder="Column visibility and order"
              onChange={() => {}}
              optionLabelProp="label"
            >
              <Select.Option value={'filterData'} label={'filterData'}>
                <div className="demo-option-label-item">filterData</div>
              </Select.Option>
            </Select>
          </Col>
          <Col
            className={modalStyles.Buttons}
            xs={24}
            sm={12}
            md={9}
            lg={8}
            xl={7}
          >
            <ButtonsDoubleAction
              onClickPrimary={() => setFilterModal(false)}
              onClickOther={() => setFilterModal(false)}
              titlePrimary="Apply filter"
              otherTitle="Remove"
              typeButtons={'ok-and-remove'}
            />
          </Col>
        </Row>
        <h4>Your filters</h4>
        <Row className={modalStyles.Row}>
          <Col
            className={modalStyles.Input}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
          >
            <Form layout="vertical">
              <FormInput
                type="text"
                required
                options={{ key: 'entry_0', label: 'Can add entry' }}
              />
              <FormInput
                type="text"
                required
                options={{ key: 'entry_1', label: 'Can add entry' }}
              />
              <FormInput
                type="text"
                required
                options={{ key: 'entry_2', label: 'Can add entry' }}
              />
            </Form>
          </Col>
        </Row>
      </Modal>
      <Layout style={{ minHeight: '100vh' }}>
        <SidebarMenu />
        <Layout className="site-layout">
          <Content style={{ margin: '0 16px' }}>
            <div className="site-layout-background" style={{ padding: 24 }}>
              <BreadcrumbComponent breadcrumb={['Settings', 'Users']} />
              <Title
                style={{ color: '#0A365C', lineHeight: 1.2, marginTop: 10 }}
                level={1}
              >
                Users
              </Title>

              <Filter
                textButton2="Create new user"
                onClickBuutton2={() => history.push('/settings/users/create')}
                filterModal={setFilterModal}
                inputFilterData={[
                  'User name',
                  'Email address',
                  'First name',
                  'Last name',
                  'Groups',
                  'Actions',
                ]}
                textCount="200 users"
              />

              <div className={styles.TableDesktop}>
                <TableGrid
                  rowSelection={{
                    type: '',
                    ...rowSelection,
                  }}
                  columns={[
                    {
                      title: 'Name',
                      dataIndex: 'name',
                      key: 'name',
                    },
                    {
                      title: 'Email Address',
                      dataIndex: 'display_name',
                      key: 'display_name',
                    },
                    {
                      title: 'First name',
                      dataIndex: 'type',
                      key: 'type',
                    },
                    {
                      title: 'Last name',
                      dataIndex: 'category',
                      key: 'category',
                    },
                    {
                      title: 'Permissions',
                      dataIndex: 'vendor',
                      key: 'vendor',
                      render: (tags: any) => (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Tag color={'geekblue'}>PERMISSION MEMBER</Tag>
                          <Tag color={'geekblue'}>PERMISSION MEMBER</Tag>
                          <Tag color={'geekblue'}>PERMISSION MEMBER</Tag>

                          <Button
                            style={{
                              background: '#F4F7F9',
                              padding: '2px',
                              borderRadius: 5,
                              lineHeight: 0,
                              height: 24,
                              borderColor: '#eee',
                            }}
                            onClick={() => setPermissionsModal(true)}
                            className={styles.ButtonDropdown}
                          >
                            <HiDotsHorizontal color="#333" size={20} />
                          </Button>
                        </div>
                      ),
                    },
                    {
                      title: 'Actions',
                      key: 'actions',
                      render: () => (
                        <div className={styles.ActionButtons}>
                          <button className={styles.ActionButtonUpdate}>
                            <HiPencil />
                          </button>

                          <button className={styles.ActionButtonRemove}>
                            <FaTrashAlt />
                          </button>
                        </div>
                      ),
                    },
                  ]}
                  data={[
                    {
                      key: '1',
                      name: 'Teste',
                      display_name: 'email@email.com',
                      type: 'Jhon',
                      category: 'Doe',
                      vendor: 'Admin',
                    },
                    {
                      key: '2',
                      name: 'Teste2',
                      display_name: 'email@email.com',
                      type: 'Jhon2',
                      category: 'Doe2',
                      vendor: 'Admin, Member, Developer',
                    },
                  ]}
                />
              </div>

              <div className={styles.TableResponsive}>
                <List
                  itemLayout="horizontal"
                  pagination={{
                    onChange: (page) => {
                      console.log(page)
                    },
                    pageSize: 3,
                  }}
                  size="large"
                  dataSource={data}
                  renderItem={(item: any) => (
                    <List.Item>
                      <Checkbox
                        style={{ margin: '5px 0 15px 0' }}
                        onChange={() => {}}
                      >
                        Select
                      </Checkbox>
                      <List.Item.Meta
                        title={item.name.name}
                        description={item.name.value}
                      />
                      <List.Item.Meta
                        title={item.displayName.name}
                        description={item.displayName.value}
                      />
                      <List.Item.Meta
                        title={item.description.name}
                        description={item.description.value}
                      />
                      <List.Item.Meta
                        title={item.category.name}
                        description={item.category.value}
                      />
                      <List.Item.Meta title={item.groups.name} />
                      <List.Item.Meta title={item.groups.name} />
                      {item.groups.value.length <= 5 ? (
                        item.groups.value.map((perm: any) => {
                          return (
                            <>
                              <Tag color={'geekblue'} key={perm}>
                                {perm.toUpperCase()}
                              </Tag>
                            </>
                          )
                        })
                      ) : (
                        <>
                          {Array.from({ length: 5 }, (value, key) => {
                            return (
                              <Tag
                                style={{ marginBottom: 8 }}
                                color={'geekblue'}
                                key={item.groups.value[key]}
                              >
                                {item.groups.value[key].toUpperCase()}
                              </Tag>
                            )
                          })}
                          {/* @ts-ignore */}

                          <Button
                            style={{
                              background: '#F4F7F9',
                              padding: '2px',
                              borderRadius: 5,
                              lineHeight: 0,
                              height: 24,
                              borderColor: '#eee',
                            }}
                            onClick={() => setPermissionsModal(true)}
                            className={styles.ButtonDropdown}
                          >
                            <HiDotsHorizontal color="#333" size={20} />
                          </Button>
                        </>
                      )}
                      <List.Item.Meta title={'Actions'} />
                      <div className={styles.ActionButtons}>
                        <button className={styles.ActionButtonUpdate}>
                          <HiPencil />
                        </button>
                        <button className={styles.ActionButtonMap}>
                          <FaBrain />
                        </button>
                        <button className={styles.ActionButtonRemove}>
                          <FaTrashAlt />
                        </button>
                      </div>
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}
