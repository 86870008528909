import { useState } from 'react'
import modalStyles from '../../../css/modal.module.scss'
import styles from '../../Auth/auth.module.scss'
import BreadcrumbComponent from '../../../components/Breadcrumb'
import { Col, Row, Typography, Form } from 'antd'
import { Layout } from 'antd'
const { Content } = Layout
import SidebarMenu from 'components/SidebarMenu'
import Modal from 'antd/lib/modal/Modal'
import FormInput from 'components/Form/input'
import Upload from 'components/UploadFile'
import '../../../css/modal.css'
import ButtonsDoubleAction from 'components/Buttons/buttonsDoubleAction'

const { Title } = Typography

export default function ProfileUpdate({ history }: any) {
  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <SidebarMenu />
        <Layout className="site-layout">
          <Content style={{ margin: '0 16px' }}>
            <div className="site-layout-background" style={{ padding: 24 }}>
              <BreadcrumbComponent breadcrumb={['Profile', 'Update']} />
              <Title style={{ color: '#0A365C', lineHeight: 1.2, marginTop: 10 }} level={1}>
                Profile Update
              </Title>
              <form className={styles.AuthForm}>
                <Row gutter={[30, 20]}>
                  <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={5}>
                    <Upload />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={19}>
                    <Row gutter={[40, 20]}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <FormInput
                          type="text"
                          required
                          options={{ key: 'firstName', label: 'First name' }}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <FormInput
                          type="text"
                          required
                          options={{ key: 'lastName', label: 'LastName' }}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <FormInput
                          type="text"
                          required
                          options={{ key: 'username', label: 'Username' }}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <FormInput
                          type="email"
                          required
                          options={{ key: 'email', label: 'Email Address' }}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <FormInput
                          type="password"
                          required
                          options={{ key: 'password', label: 'Password' }}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <FormInput
                          type="password"
                          required
                          options={{
                            key: 'confirmPassword',
                            label: 'Confirm password',
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <div className="mt-buttons">
                  <ButtonsDoubleAction titlePrimary="Update" otherTitle="Cancel" />
                </div>
              </form>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}
