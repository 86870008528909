import { Table } from 'antd'
import './styles.scss'

export default function TableGrid({ columns, data, ...rest }: any) {
  return (
    <div className="table-responsive">
      <Table columns={columns} dataSource={data} {...rest} />
    </div>
  )
}
