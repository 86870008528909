import styles from './styles.module.scss'

export default function CardMini({ title, value, color, stage }: any) {
  if (!stage) {
    return (
      <div style={{ backgroundColor: color }} className={styles.CardMini}>
        <small>{title}</small>
        <h3>{value}</h3>
      </div>
    )
  }

  return (
    <div style={{ backgroundColor: color }} className={styles.CardMiniSateg2}>
      <small>{title}</small>
      <h3>{value}</h3>
    </div>
  )
}
