import { Col, Row, Tabs } from 'antd'
import styles from './memory.module.scss'
import ServerInformation from 'components/Server/informations'

export default function SoftwareContentTabs({ setModal, setModal2 }: any) {
  return (
    <div className={styles.Container}>
      <ServerInformation setModal={setModal} />

      <div className={styles.Informations}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Asset:</label>
              <h2>asset name</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Manufacture:</label>
              <h2>Proxmox</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Type:</label>
              <h2>application</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Other field:</label>
              <h2>field infos</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Other field:</label>
              <h2>field infos</h2>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles.CustomTable}>
        {[0, 1, 2].map((key) => (
          <div key={key} className={styles.Item}>
            <div>
              <small>Version</small>
              <h4>4</h4>
            </div>
            <div>
              <small>Requires license</small>
              <h4 style={{ color: 'green' }}>Yes</h4>
            </div>
            <div>
              <small>Released at</small>
              <h4>2002-06-24</h4>
            </div>
            <div>
              <small>End of life at</small>
              <h4>2007-06-30</h4>
            </div>

            <div>
              <small>Details</small>
              <h4 onClick={() => setModal2(true)} className={styles.ViewDetails}>
                view details
              </h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
