import styles from '../../Auth/auth.module.scss'
import BreadcrumbComponent from '../../../components/Breadcrumb'
import { Col, Row, Typography } from 'antd'
import { Layout } from 'antd'
const { Content } = Layout
import SidebarMenu from 'components/SidebarMenu'
import FormInput from 'components/Form/input'
import FormSelect from 'components/Form/select'
import FormTextArea from 'components/Form/textarea'
import '../../../css/modal.css'
import ButtonsDoubleAction from 'components/Buttons/buttonsDoubleAction'
import FormSelectMultiples from 'components/Form/multiplesSelect'

const { Title } = Typography

export default function SettingsUpdateGroups({ history }: any) {
  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <SidebarMenu />
        <Layout className="site-layout">
          <Content style={{ margin: '0 16px' }}>
            <div className="site-layout-background" style={{ padding: 24 }}>
              <BreadcrumbComponent breadcrumb={['Groups update']} />
              <Title
                style={{ color: '#0A365C', lineHeight: 1.2, marginTop: 10 }}
                level={1}
              >
                Groups update
              </Title>
              <form className={styles.AuthForm}>
                <Row gutter={[40, 20]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <FormInput
                      type="text"
                      required
                      defaultValue="Admin"
                      options={{ key: 'name', label: 'Group name' }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <FormSelectMultiples
                      type="text"
                      required
                      options={{ key: 'users', label: 'Users' }}
                      optionsSelect={['User 1', 'User 2']}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <FormSelectMultiples
                      type="text"
                      required
                      options={{ key: 'permissions', label: 'Permissions' }}
                      optionsSelect={['PERMISSION MEMBER', 'PERMISSION ADMIN']}
                    />
                  </Col>
                </Row>
                <div className="mt-buttons">
                  <ButtonsDoubleAction
                    titlePrimary="Update"
                    otherTitle="Cancel"
                  />
                </div>
              </form>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}
