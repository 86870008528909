import { useState } from 'react'
import modalStyles from '../../../css/modal.module.scss'
import styles from '../../Auth/auth.module.scss'
import BreadcrumbComponent from '../../../components/Breadcrumb'
import { Col, Row, Typography, Form } from 'antd'
import Icon, { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Layout } from 'antd'
const { Content } = Layout
import SidebarMenu from 'components/SidebarMenu'
import Modal from 'antd/lib/modal/Modal'
import FormInput from 'components/Form/input'
import '../../../css/modal.css'
import ButtonsDoubleAction from 'components/Buttons/buttonsDoubleAction'
import UploadFile from 'components/UploadFile'

const { Title } = Typography

export default function SettingsUserUpdate({ history }: any) {
  const [servicesModal, setServicesModal] = useState(false)
  const [vendorModal, setVendorModal] = useState(false)
  const [state, setState] = useState<any>({
    previewVisible: false,
    previewImage: '',
    fileList: [],
  })
  const { previewVisible, previewImage, fileList } = state

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  )

  const handleCancel = () => setState({ previewVisible: false })

  const handleChange = ({ fileList }: any) => setState({ fileList })

  return (
    <>
      <Modal
        style={{ zIndex: 99999999 }}
        footer={[]}
        className={modalStyles.Modal}
        width={900}
        visible={servicesModal}
        onCancel={() => setServicesModal(false)}
      >
        <h4>Update user</h4>

        <Row className={modalStyles.Row}>
          <Col className={modalStyles.Input} xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form layout="vertical">
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormInput
                    type="text"
                    required
                    options={{ key: 'new_service_name', label: 'Name' }}
                  />
                </Col>
              </Row>
              <div style={{ marginTop: 20 }}>
                <ButtonsDoubleAction
                  titlePrimary="Create"
                  otherTitle="Cancel"
                  onClickOther={() => setServicesModal(false)}
                  onClickPrimary={() => setServicesModal(false)}
                />
              </div>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        style={{ zIndex: 99999999 }}
        footer={[]}
        className={modalStyles.Modal}
        width={900}
        visible={vendorModal}
        onCancel={() => setVendorModal(false)}
      >
        <h4>Create new Vendor</h4>

        <Row className={modalStyles.Row}>
          <Col className={modalStyles.Input} xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form layout="vertical">
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormInput
                    type="text"
                    required
                    options={{ key: 'new_vendor_name', label: 'Name' }}
                  />
                </Col>
              </Row>
              <div style={{ marginTop: 20 }}>
                <ButtonsDoubleAction
                  titlePrimary="Create"
                  otherTitle="Cancel"
                  onClickOther={() => setVendorModal(false)}
                  onClickPrimary={() => setVendorModal(false)}
                />
              </div>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Layout style={{ minHeight: '100vh' }}>
        <SidebarMenu />
        <Layout className="site-layout">
          <Content style={{ margin: '0 16px' }}>
            <div className="site-layout-background" style={{ padding: 24 }}>
              <BreadcrumbComponent breadcrumb={['Settings', 'Users', 'Users update']} />
              <Title style={{ color: '#0A365C', lineHeight: 1.2, marginTop: 10 }} level={1}>
                Users update
              </Title>
              <form className={styles.AuthForm}>
                <Row gutter={[30, 20]}>
                  <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={5}>
                    <UploadFile />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={19}>
                    <Row gutter={[40, 20]}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <FormInput
                          type="text"
                          required
                          options={{ key: 'firstName', label: 'First name' }}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <FormInput
                          type="text"
                          required
                          options={{ key: 'lastName', label: 'LastName' }}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <FormInput
                          type="text"
                          required
                          options={{ key: 'username', label: 'Username' }}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <FormInput
                          type="email"
                          required
                          options={{ key: 'email', label: 'Email Address' }}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <FormInput
                          type="password"
                          required
                          options={{ key: 'password', label: 'Password' }}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <FormInput
                          type="password"
                          required
                          options={{
                            key: 'confirmPassword',
                            label: 'Confirm password',
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <div className="mt-buttons">
                  <ButtonsDoubleAction titlePrimary="Update" otherTitle="Cancel" />
                </div>
              </form>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}
