import styles from './index.module.css'
import { IoMenu, IoAddSharp } from "react-icons/io5"

export default function CardRegister() {
  return (
    <div className={styles.Card}>
      <div className={styles.Icon}><IoMenu size={26} color="#FFF" /></div>
      <div className={styles.Texts}>
        <h4>Consult or register a new rack</h4>
        <p>Lorem impsum dolor sit amet, consectertur adipiscing elit, sed do euismod tempor.</p>
      </div>
      <div className={styles.Buttons}>
        <button><IoAddSharp size={30} color="#F2F2F2" /></button>
        <button>View racks</button>
      </div>
    </div>
  )
}