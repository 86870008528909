import BreadcrumbComponent from '../../../components/Breadcrumb'
import { Layout, Tabs } from 'antd'
const { Content } = Layout
import SidebarMenu from 'components/SidebarMenu'
import { Typography } from 'antd'
import './tabs.scss'
import TabGeneralInformation from './Tabs/generalInformations'
import TabManufacture from './Tabs/manufacture'
import TabDone from './Tabs/done'

const { Title } = Typography

export default function DevicesServersUpdate({ history }: any) {
  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <SidebarMenu />
        <Layout className="site-layout">
          <Content style={{ margin: '0 16px' }}>
            <div className="site-layout-background" style={{ padding: 24 }}>
              <BreadcrumbComponent
                breadcrumb={['Devices', 'Servers', 'Update']}
              />
              <Title
                style={{ color: '#0A365C', lineHeight: 1.2, marginTop: 10 }}
                level={1}
              >
                Update server
              </Title>
              <Tabs type="card" defaultActiveKey="1" size="large">
                <Tabs.TabPane tab="General informations" key="1">
                  <TabGeneralInformation />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Manufacture" key="2">
                  <TabManufacture />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Done" key="3">
                  <TabDone />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}
