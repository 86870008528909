import React from 'react'
import ReactDOM from 'react-dom'

import App from './pages/Dashboard'

import 'antd/dist/antd.css'
import './css/styles.css'
import Auth from './pages/Auth/auth'
import { BrowserRouter, Route } from 'react-router-dom'
import Forgot from './pages/Auth/forgot'
import Register from './pages/Auth/register'
import Services from './pages/Dashboard/Services/services'
import CreateService from './pages/Dashboard/Services/create'

import MapService from 'pages/Dashboard/Services/map'
import UpdateService from 'pages/Dashboard/Services/update'
import SettingsUser from 'pages/Dashboard/Settings/user'
import SettingsUserUpdate from 'pages/Dashboard/Settings/userUpdate'
import SettingsGroups from 'pages/Dashboard/Settings/groups'
import SettingsCreateGroups from 'pages/Dashboard/Settings/createGroups'
import SettingsUpdateGroups from 'pages/Dashboard/Settings/updateGroups'
import SettingsAgent from 'pages/Dashboard/Settings/agent'
import DevicesServers from 'pages/Dashboard/Devices/server'
import DevicesServersCreate from 'pages/Dashboard/Devices/createServer'
import DevicesServersUpdate from 'pages/Dashboard/Devices/updateServer'
import Profile from 'pages/Dashboard/Profile'
import ProfileUpdate from 'pages/Dashboard/Profile/update'
import SettingsCreateUser from 'pages/Dashboard/Settings/createUser'
import SoftwareInstallation from 'pages/Dashboard/Software/update'
import SettingsUserView from 'pages/Dashboard/Settings/view'
import SettingsViewGroups from 'pages/Dashboard/Settings/viewGroup'
import ServersView from 'pages/Dashboard/Servers/view'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Route exact path="/auth" component={Auth} />
      <Route exact path="/auth/forgot-password" component={Forgot} />
      <Route exact path="/auth/register" component={Register} />

      <Route exact path="/" component={App} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/services/create" component={CreateService} />

      <Route exact path="/services/map" component={MapService} />
      <Route exact path="/services/update" component={UpdateService} />
      <Route exact path="/settings/users" component={SettingsUser} />
      <Route exact path="/settings/users/update" component={SettingsUserUpdate} />
      <Route exact path="/settings/users/create" component={SettingsCreateUser} />
      <Route exact path="/settings/groups" component={SettingsGroups} />
      <Route exact path="/settings/groups/create" component={SettingsCreateGroups} />
      <Route exact path="/settings/groups/update" component={SettingsUpdateGroups} />
      <Route exact path="/settings/agent" component={SettingsAgent} />
      <Route exact path="/devices/servers" component={DevicesServers} />
      <Route exact path="/devices/server/create" component={DevicesServersCreate} />
      <Route exact path="/devices/server/update" component={DevicesServersUpdate} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/profile/update" component={ProfileUpdate} />
      <Route exact path="/software/installation" component={SoftwareInstallation} />
      <Route exact path="/services/map" component={MapService} />
      <Route exact path="/settings/users/view" component={SettingsUserView} />
      <Route exact path="/settings/groups/view" component={SettingsViewGroups} />
      <Route exact path="/servers/view" component={ServersView} />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
