import { Col, Row, Tabs } from 'antd'
import styles from './memory.module.scss'
import ServerInformation from 'components/Server/informations'

export default function UserContentTabs({ setModal }: any) {
  return (
    <div className={styles.Container}>
      <ServerInformation setModal={setModal} />

      <div className={styles.Informations}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Server:</label>
              <h2>danilo-colo1</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Name:</label>
              <h2>root</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Uid:</label>
              <h2>3232</h2>
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Gid:</label>
              <h2>37923</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Gecos:</label>
              <h2>information data</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Home:</label>
              <h2>/</h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
            <div className={styles.Item}>
              <label>Shell:</label>
              <h2>/bin/bash</h2>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
