import { Col, Input, Row } from 'antd'
import styles from './register.module.scss'
import { FloatLabel } from '../../../components/Form/floatLable'
import { IoMdArrowForward, IoMdArrowBack } from 'react-icons/io'

export default function OrganizationStep({
  step,
  setStep,
  organization,
  setOrganization,
}: any) {
  return (
    <Row justify="space-between">
      <Col span={24}>
        <FloatLabel
          value={organization}
          label="Organization name (domain name)"
          name="email"
        >
          <Input
            type="text"
            value={organization}
            onChange={(e) => setOrganization(e.target.value)}
            className={styles.Input}
            autoComplete="off"
          />
        </FloatLabel>
      </Col>

      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <div style={{ paddingRight: 15 }} className={styles.Col}>
          <button onClick={() => setStep(0)} className={styles.Previous}>
            {/* <IoMdArrowBack size={18} color="#555" /> */}
            Previous step
          </button>
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <div style={{ paddingLeft: 15 }} className={styles.Col}>
          <button onClick={() => setStep(2)} className={styles.Next}>
            Next step <IoMdArrowForward size={18} />
          </button>
        </div>
      </Col>
    </Row>
  )
}
