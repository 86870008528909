import BreadcrumbComponent from '../../../components/Breadcrumb'
import { Layout } from 'antd'
const { Content } = Layout
import SidebarMenu from 'components/SidebarMenu'
import { Col, Row, Typography } from 'antd'
import styles from './agent.module.scss'
import { MdContentCopy } from 'react-icons/md'
import { useRef } from 'react'

const { Title } = Typography

export default function SettingsAgent({ history }: any) {
  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <SidebarMenu />
        <Layout className="site-layout">
          <Content style={{ margin: '0 16px' }}>
            <div className="site-layout-background" style={{ padding: 24 }}>
              <BreadcrumbComponent breadcrumb={['Settings', 'Agent']} />
              <Title
                style={{ color: '#0A365C', lineHeight: 1.2, marginTop: 10 }}
                level={1}
              >
                <div className={styles.AgentInformations}>
                  <Row gutter={[30, 20]}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={6}>
                      <div className={styles.Profile}>
                        <div className={styles.ProfileAvatar}>
                          <img
                            src="http://afernandes.adv.br/wp-content/uploads/Team-Member-3.jpg"
                            alt=""
                          />
                        </div>
                        <h4>Felipe Henrique</h4>
                        <p>Administrador</p>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={16} xl={18}>
                      <div className={styles.Informations}>
                        <div>
                          <small>Name:</small>
                          <h3>Felipe Henrique Silva</h3>
                        </div>
                        <div>
                          <small>Username:</small>
                          <h3>felipecreativeddcombr</h3>
                        </div>
                        <div>
                          <small>Email Address:</small>
                          <h3>felipe@creativedd.com.br</h3>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className={styles.AgentInformations}>
                  <div className={styles.AgentHeader}>Download agent</div>
                  <div className={styles.DownloadAgent}>
                    <p>
                      Jxa&E5EA7gV^@E3cVsfwEnATBDe=Xt_4SnL-*Za+Jg6vfC?&Q=aZ2J^ZzJNc6ndCEm*&$PXxUGB@tCH8r%gSztn$Z7m#?gxLEU7f62FP-5EHZB9$aEDf87tm@j7J29GeGxBe?hQAR*t=kX=_s?+XNLK8f%&8wNJFM_&k_mGGm8-^7fvX8hV@PnJ5?dA%=mz!Qjjq!MEaKwuSP=ULz^nD4#tcy8Ln6NSjanS_ht%6m6p+-#F-NQkN6c_JhX!Rh8j-
                    </p>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(
                          'Jxa&E5EA7gV^@E3cVsfwEnATBDe=Xt_4SnL-*Za+Jg6vfC?&Q=aZ2J^ZzJNc6ndCEm*&$PXxUGB@tCH8r%gSztn$Z7m#?gxLEU7f62FP-5EHZB9$aEDf87tm@j7J29GeGxBe?hQAR*t=kX=_s?+XNLK8f%&8wNJFM_&k_mGGm8-^7fvX8hV@PnJ5?dA%=mz!Qjjq!MEaKwuSP=ULz^nD4#tcy8Ln6NSjanS_ht%6m6p+-#F-NQkN6c_JhX!Rh8j-'
                        )
                      }}
                      title="Copy"
                    >
                      <MdContentCopy />
                    </button>
                  </div>
                </div>
              </Title>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}
