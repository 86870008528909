import { Typography } from 'antd'
const { Title } = Typography
import { Pie, measureTextWidth } from '@ant-design/charts'

const DemoPie = ({ title, data, color }: any) => {
  function renderStatistic(containerWidth: any, text: any, style: any) {
    //@ts-ignore
    var _measureTextWidth = (0, measureTextWidth)(text, style),
      textWidth = _measureTextWidth.width,
      textHeight = _measureTextWidth.height
    var R = containerWidth / 2
    var scale = 1
    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      )
    }
    var textStyleStr = 'width:'.concat(containerWidth, 'px;')
    return (
      '<div style="'
        .concat(textStyleStr, ';font-size:')
        .concat(scale.toString(), 'em;line-height:')
        //@ts-ignore
        .concat(scale < 1 ? 1 : 'inherit', ';">')
        .concat(text, '</div>')
    )
  }

  var config = {
    appendPadding: 2,
    data: data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.8,

    legend: {
      layout: 'vertical',
      position: 'top-left',
      size: '10px',
      fontSize: 18,
    },
    meta: {
      value: {
        formatter: function formatter(v: any) {
          return ''.concat(v, ' \xA5')
        },
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: { textAlign: 'center', fontSize: 12 },
      autoRotate: false,
      content: '{value}',
    },
    color,
    statistic: {
      title: {
        offsetY: -4,
        style: { fontSize: '15px' },
        customHtml: function customHtml(container: any, view: any, datum: any) {
          var _container$getBoundin = container.getBoundingClientRect(),
            width = _container$getBoundin.width,
            height = _container$getBoundin.height
          var d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2))
          var text = datum ? datum.type : ''
          return `% ${text.split(' ')[1] || text}`
        },
      },
      content: {
        offsetY: 4,
        style: { fontSize: '15px' },
        customHtml: function customHtml(
          container: any,
          view: any,
          datum: any,
          data: any
        ) {
          var _container$getBoundin2 = container.getBoundingClientRect(),
            width = _container$getBoundin2.width
          var text = datum
            ? '% '.concat(datum.value)
            : '% '.concat(
                data.reduce(function (r: any, d: any) {
                  return r + d.value
                }, 0)
              )
          return renderStatistic(width, text, { fontSize: 18 })
        },
      },
    },

    interactions: [
      { type: 'element-selected' },
      { type: 'element-active' },
      { type: 'pie-statistic-active' },
    ],
  }
  return (
    <>
      <Title style={{ color: '#0A365C', lineHeight: 1.4 }} level={2}>
        {title}
      </Title>
      {/* @ts-ignore */}
      <Pie {...config} />
    </>
  )
}

export default DemoPie
