import { useState } from 'react'
import { MdDevices, MdDashboard, MdReportProblem } from 'react-icons/md'
import { SiReadthedocs } from 'react-icons/si'
import {
  AiOutlineCodeSandbox,
  AiOutlineShop,
  AiOutlinePoweroff,
} from 'react-icons/ai'
import { FaAlignJustify, FaNetworkWired, FaBell } from 'react-icons/fa'
import { FiSettings, FiLayers } from 'react-icons/fi'
import { ImPower } from 'react-icons/im'
import { BiMap } from 'react-icons/bi'
import {
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosArrowBack,
} from 'react-icons/io'

import { Button, Layout, Menu, Avatar } from 'antd'

import './index.css'

const { Sider } = Layout
const { SubMenu } = Menu

const SidebarMenu = () => {
  const [collapsed, setCollapsed] = useState(false)

  const handleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  return (
    <Sider
      style={{
        overflow: 'auto',
        height: '100vh',
        position: collapsed ? 'sticky' : 'fixed',
        zIndex: 99,
        top: 0,
        left: 0,
      }}
      className="sidebarMenu"
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      width={260}
    >
      <div className="logoWrap">
        {!collapsed ? (
          <div className="logo">
            <img
              src="https://www.icmdb.io/wp-content/uploads/2021/05/logo.png"
              alt=""
            />
          </div>
        ) : (
          <div className="logo">
            <img
              style={{ width: 80 }}
              src="https://icmdb.creativedd.com.br/pontinhos.png"
              alt=""
            />
          </div>
        )}
        <div className={collapsed ? 'buttonCollapse' : 'buttonNoCollapse'}>
          <Button
            onClick={handleCollapsed}
            type="primary"
            icon={
              collapsed ? (
                <IoIosArrowForward color={'#0a253f'} />
              ) : (
                <IoIosArrowBack color={'#0a253f'} />
              )
            }
          />
        </div>
      </div>

      <div className="sidebarMenu__infos">
        <div className="avatar">
          <Avatar size={60} src="https://i.pravatar.cc/150?img=59" />
        </div>
        <div className="infos">
          <Button type="primary" icon={<FaBell />} />
          {!collapsed && (
            <>
              <div>
                <span>Felipe Henrique</span>
                <small>Administrador</small>
              </div>
              <Button type="primary" icon={<IoIosArrowDown />} />
            </>
          )}
        </div>
      </div>

      <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
        <Menu.Item icon={<MdDashboard />}>Dashboard</Menu.Item>

        <SubMenu title="Assets" icon={<SiReadthedocs />}>
          <Menu.Item>Assets</Menu.Item>
          <Menu.Item>Asset Types</Menu.Item>
        </SubMenu>

        <SubMenu title="Devices" icon={<MdDevices />}>
          <Menu.Item>BIOs</Menu.Item>
          <Menu.Item>Baseboards</Menu.Item>
          <Menu.Item>Chassis</Menu.Item>
          <Menu.Item>Cpus</Menu.Item>
          <Menu.Item>Disks</Menu.Item>
          <Menu.Item>File Systems</Menu.Item>
          <Menu.Item>Memory Array</Menu.Item>
          <Menu.Item>Memory Devices</Menu.Item>
          <Menu.Item>PDUs</Menu.Item>
          <Menu.Item>PDU Models</Menu.Item>
          <Menu.Item>Processes</Menu.Item>
          <Menu.Item>RAM</Menu.Item>
          <Menu.Item>Servers</Menu.Item>
          <Menu.Item>Storage Controllers</Menu.Item>
        </SubMenu>

        <SubMenu title="Inventory" icon={<FiLayers />}>
          <Menu.Item>Buidlings</Menu.Item>
          <Menu.Item>Cables</Menu.Item>
          <Menu.Item>Manufacturers</Menu.Item>
          <Menu.Item>Room Groups</Menu.Item>
          <Menu.Item>Rooms</Menu.Item>
        </SubMenu>

        <SubMenu title="IPAM" icon={<BiMap />}>
          <Menu.Item>Aggregates</Menu.Item>
          <Menu.Item>Arps</Menu.Item>
          <Menu.Item>IP Addresses</Menu.Item>
          <Menu.Item>Interfaces</Menu.Item>
          <Menu.Item>Macs</Menu.Item>
          <Menu.Item>Ouis</Menu.Item>
          <Menu.Item>Prefixes</Menu.Item>
          <Menu.Item>RIRs</Menu.Item>
          <Menu.Item>Roles</Menu.Item>
          <Menu.Item>Switch groups</Menu.Item>
          <Menu.Item>Switches</Menu.Item>
          <Menu.Item>Vlan Groups</Menu.Item>
          <Menu.Item>VLANs</Menu.Item>
          <Menu.Item>VRFs</Menu.Item>
        </SubMenu>

        <Menu.Item icon={<FaNetworkWired />}>Services</Menu.Item>
        <Menu.Item icon={<ImPower />}>Power Circuits</Menu.Item>
        <Menu.Item icon={<FaAlignJustify />}>Racks</Menu.Item>
        <Menu.Item icon={<AiOutlineShop />}>Vendors</Menu.Item>

        {/* <Menu.Item icon={<MdReportProblem />}>Reports</Menu.Item> */}

        <SubMenu title="Settings" icon={<FiSettings />}>
          <Menu.Item>User</Menu.Item>
          <Menu.Item>Groups</Menu.Item>
        </SubMenu>

        <SubMenu title="Software" icon={<AiOutlineCodeSandbox />}>
          <Menu.Item>Software Installations</Menu.Item>
          <Menu.Item>Software Licenses</Menu.Item>
          <Menu.Item>Software Versions</Menu.Item>
          <Menu.Item>Software</Menu.Item>
        </SubMenu>

        <Menu.Item icon={<AiOutlinePoweroff />}>Logout</Menu.Item>
      </Menu>
      {collapsed ? (
        ''
      ) : (
        <div className="botttom">
          <p>Needing help?</p>
          <div
            style={{ margin: '0 auto', textAlign: 'center' }}
            className="m-auto"
          >
            <button>Contact us</button>
          </div>
        </div>
      )}
    </Sider>
  )
}

export default SidebarMenu
