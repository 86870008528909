import { useState } from 'react'
import { Col, Row } from 'antd'
import FormInput from 'components/Form/input'
import FormSelectMultiples from 'components/Form/multiplesSelect'
import FormSelect from 'components/Form/select'
import FormTextArea from 'components/Form/textarea'
import styles from './tabs.module.scss'
import ButtonsDoubleAction from 'components/Buttons/buttonsDoubleAction'
import Checkbox from 'antd/lib/checkbox/Checkbox'


export default function TabGeneralInformation() {

  const [tabActive, setTabActive] = useState('Tab viewer 1')
  const tabs = [
    'Tab viewer 1',
    'Tab viewer 2',
    'Tab viewer 3',
    'Tab viewer 4',
    'Tab viewer 5',
    'Tab viewer 6',
  ]
  return (
    <Row style={{ marginTop: 15 }} gutter={[20, 20]}>

      <Col xs={24} sm={24} md={24} lg={8} xl={6}>
        <ul className={styles.TabViewer}>
          {tabs.map((tab) => (
            <li
              key={tab}
              onClick={() => setTabActive(tab)}
              className={tabActive === tab ? styles.Active : ''}
            >
              {tab}
            </li>
          ))}
        </ul>
      </Col>
      <Col xs={24} sm={24} md={24} lg={16} xl={18}>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <FormSelect
              hasButton={true}
              buttonAction={() => alert('modal')}
              options={{ key: 'asset', label: 'Asset' }}
              optionsSelect={['Select an asset', 'Asset 1']}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <FormSelect
              hasButton={true}
              buttonAction={() => alert('modal')}
              options={{ key: 'rack', label: 'Rack' }}
              optionsSelect={['Select an rack', 'Rack 1']}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <FormInput
              type="text"
              required
              options={{ key: 'hostname', label: 'Hostname' }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <FormInput
              type="text"
              required
              options={{ key: 'serial', label: 'Serial no' }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormTextArea options={{ key: 'comments', label: 'Comments' }} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Checkbox onChange={() => {}}>Active</Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormSelectMultiples
              type="text"
              required
              options={{ key: 'services', label: 'Services' }}
              optionsSelect={[
                'Service 01',
                'Service 02',
                'Service 03',
                'Service 04',
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="mt-buttons">
              <ButtonsDoubleAction
                titlePrimary="Save informations"
                otherTitle="Cancel"
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
