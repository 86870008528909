import { Input } from 'antd'
import styles from './styles.module.scss'

export default function FormInput({
  options,
  hasButton,
  buttonAction,
  placeholder,
  value,
  ...rest
}: any) {
  return (
    <div className={styles.InputGroup}>
      <label htmlFor={options.key}>{options.label}</label>
      <div>
        <Input
          value={value}
          placeholder={placeholder}
          id={options.key}
          {...rest}
        />
        {hasButton ? (
          <button onClick={buttonAction} type="button">
            +
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
