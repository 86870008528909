import { Breadcrumb } from 'antd'
import { MdDashboard } from 'react-icons/md'

export default function BreadcrumbComponent({
  breadcrumb,
}: {
  breadcrumb: Array<string>
}) {
  return (
    <Breadcrumb>
      <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
      {breadcrumb.map((bda) => (
        <Breadcrumb.Item>{bda}</Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}
